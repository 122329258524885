import {
  SnickersProduct,
  SnickersProductDetails,
} from '@hultafors/snickers/types';

export const convertDetailsToList = (
  details: SnickersProductDetails,
  productListId: string,
): SnickersProduct => {
  const listItem: SnickersProduct = {
    productId: details.id,
    productListId,
    name: details.name,
    sku: details.sku,
    isNew: details.isNew,
    certificates: details.certifications,
    image: {
      url: details.images[0]?.url || '',
    },
    category: details.category,
    price: details.price?.value,
    currency: details.price?.currency,
    // parentSlug?: string;
    // slu?: string;
    // attributes?: { label: string; value: string | number }[];
    // TODO these are dubious
    // isPremium?: boolean;
    // categorySlug?: string;
    premiumLabel: '',
    sustainableLabel: details.sustainability[0] || '',
    isDefault: false,
    // pictograms?: any[];
    // colors?: ProductDetailsValue[];
  };
  return listItem;
};
