import { useRef } from 'react';

import { VideoFragment } from '@hultafors/snickers/types';

import {
  ControlButton,
  Video,
  VideoControls,
  VideoWrapper,
} from './VideoPlayerBlock.styled';

export const VideoPlayerBlock: React.FC<VideoFragment> = ({
  videoUrl,
  videoThumbnail,
}) => {
  const videoPlayer = useRef<HTMLVideoElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  function playVideo() {
    videoPlayer.current?.play();
    if (buttonRef.current) {
      buttonRef.current.style.display = 'none';
    }
    videoPlayer.current?.setAttribute('controls', 'controls');
  }

  return (
    <VideoWrapper>
      <Video
        preload="auto"
        ref={videoPlayer}
        poster={
          videoThumbnail
            ? `${videoThumbnail?.responsiveImage?.src}?w=1000`
            : `${videoUrl?.video?.thumbnailUrl}?w=1000`
        }
      >
        <source src={`${videoUrl?.video?.streamingUrl}`} type="video/mp4" />
        <source src={`${videoUrl?.video?.mp4Url}`} type="video/mp4" />
        <p>
          Your browser doesn&apos;t support HTML5 video. Here is a
          {' '}
          <a href={videoUrl?.video?.mp4Url || undefined}>link to the video</a>
          {' '}
          instead.
        </p>
      </Video>
      <VideoControls>
        <ControlButton
          ref={buttonRef}
          title="play"
          onClick={playVideo}
          id="controls"
        />
      </VideoControls>
    </VideoWrapper>
  );
};
