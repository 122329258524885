import { startTransition, useEffect, useState } from 'react';

import dynamic from 'next/dynamic';

// Hack for build dependencies
// eslint-disable-next-line @typescript-eslint/no-unused-vars

import {
  findRetailersMode,
  useFindRetailers,
  useOnlineStores,
} from '@hultafors/shared/api';
import { cleanTextFromUrl } from '@hultafors/shared/helpers';
import { Store } from '@hultafors/shared/types';

import { useGlobal } from '@hultafors/snickers/hooks';
import { FindRetailersPageFragment } from '@hultafors/snickers/types';

import { VisibleSeoBlock } from '../ContentArea/_contentAreaBlocks';
import { FindRetailersList } from '../FindRetailersList/FindRetailersList';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { H4 } from '../H4/H4';
import { HeroBlock } from '../hero-block/hero-block';
import { LinkBlock } from '../LinkBlock/LinkBlock';
import { Loader } from '../Loader/Loader';
import { OnlineStoreList } from '../OnlineStoreList/OnlineStoreList';
import { Paragraph } from '../Paragraph/Paragraph';
import { RetailerContainer } from '../RetailerContainer/RetailerContainer';
import { RetailersAdditionalSelections } from '../RetailersAdditionalSelections/RetailersAdditionalSelections';
import { RetailerTabsStyled } from '../RetailerTabs/RetailerTabs.styled';
import { SearchRetailersInput } from '../SearchRetailersInput/SearchRetailersInput';
import { Section } from '../Section/Section';
import { TextButton } from '../TextButton/TextButton';

const Map = dynamic(
  () => import('@hultafors/shared/components').then((mod) => mod.Map),
  {
    ssr: false,
  },
);
const Square = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.Square),
);

interface FindRetailersPageProps {
  content: FindRetailersPageFragment;
}

const DEFAULT_RADIUS = 50;

const TABS = {
  search: 0,
  onlineStores: 1,
};

export const FindRetailersPageContent: React.FC<FindRetailersPageProps> = ({
  content,
}) => {
  const { global, settings } = useGlobal();
  const {
    term,
    setTerm,
    international,
    setInternational,
    radius,
    setRadius,
    mode,
    stores,
    loading,
    clear,
    setCoords,
    coords,
    geoLoading,
  } = useFindRetailers({
    defaultRadius: DEFAULT_RADIUS,
    radiusOptions: content.radiusAlternatives.map(
      ({ radiusValue }): number => radiusValue,
    ) || [DEFAULT_RADIUS],
    defaultCoords: settings.defaultCoords,
  });
  const { stores: onlineStores = [] } = useOnlineStores();

  const [tab, setTab] = useState<number>(0);
  const [search, setSearch] = useState<string>(term || '');
  const [selectedItem, setSelectedItem] = useState<Store | undefined>(
    undefined,
  );
  const [displaySearchMessage, setDisplaySearchMessage] = useState('');

  useEffect(() => {
    if (loading) {
      startTransition(() => {
        setDisplaySearchMessage(content.noResultForYourLocation || '');
      });
    } else {
      switch (mode) {
        // TODO Don't understand this mode thing, how does it play out on snickers?
        case findRetailersMode.findRetailersByCoord:
          startTransition(() => {
            setDisplaySearchMessage(
              !term
                ? ''
                : stores.length && term
                  ? `${stores.length} ${
                    content.matchesFor || ''
                  } ”${cleanTextFromUrl(search)}”`
                  : content.noResultForYourLocation || '',
            );
          });
          break;
        case findRetailersMode.findRetailers:
          startTransition(() => {
            setDisplaySearchMessage(
              !term
                ? ''
                : stores.length && term
                  ? `${stores.length} ${
                    content.matchesFor || ''
                  } ”${cleanTextFromUrl(search)}”`
                  : content.noResultForYourLocation || '',
            );
          });
          break;
      }
    }
  }, [stores, loading]);

  const onChangeTerm: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    startTransition(() => {
      setSearch(event.target.value);
    });
  };

  const clearSearch = () => {
    startTransition(() => {
      setSearch('');
      clear();
    });
  };

  const onSubmitSearch: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    startTransition(() => {
      setTerm(search);
    });
  };

  const onChangeRadius: React.ChangeEventHandler<HTMLSelectElement> = (
    event,
  ) => {
    startTransition(() => {
      setRadius(parseInt(event.target.value.replace(' km', ''), 10));
    });
  };

  const onChangeInternational: React.ChangeEventHandler<
    HTMLInputElement
  > = () => {
    startTransition(() => {
      setInternational(!international);
    });
  };

  const selectItem = (id: string) => {
    startTransition(() => {
      setSelectedItem(stores?.find((x) => x.id === id) || selectedItem);
    });
  };

  const mapPositionChanged = async (latitude: number, longitude: number) => {
    startTransition(() => {
      setCoords({ latitude, longitude });
      setTerm('');
    });
  };

  if (!content) {
    return null;
  }

  function setSearchTab() {
    startTransition(() => {
      setTab(TABS.search);
    });
  }
  function setOnlineTab() {
    startTransition(() => {
      setTab(TABS.onlineStores);
    });
  }
  return (
    <>
      <HeroBlock
        id="find-retailers-hero"
        title={content.findStoreHeader || ''}
        heroIcon
        wide
        thin
        priority
      />
      {content.becomeRetailersLinkText && content.becomeRetailersLinkUrl && (
        <LinkBlock
          linkText={content.becomeRetailersLinkText}
          linkUrl={content.becomeRetailersLinkUrl}
          iconNext={true}
        />
      )}
      <RetailerTabsStyled>
        <div className="Tabs Center">
          <TextButton
            className={`Tab ${TABS.search === tab ? 'Active' : ''}`}
            onClick={setSearchTab}
          >
            <Paragraph size="small">
              {content.retailers || 'Retailers'}
            </Paragraph>
          </TextButton>
          <TextButton
            className={`Tab ${TABS.onlineStores === tab ? 'Active' : ''}`}
            onClick={setOnlineTab}
          >
            <Paragraph size="small">
              {content.onlineStores || 'Online stores'}
            </Paragraph>
          </TextButton>
        </div>
      </RetailerTabsStyled>

      <Section hide={tab !== TABS.search} zeroTop>
        <Grid>
          <GridChild
            columnSpan={[
              { columns: 6 },
              { breakpoint: 'mobileMax', columns: 6, start: 4 },
            ]}
          >
            <RetailerContainer>
              <SearchRetailersInput
                name="findRetailersInput"
                onChange={onChangeTerm}
                value={search}
                placeholder={content.searchExplained || ''}
                onSubmit={onSubmitSearch}
                clear={clearSearch}
                displaySearchMessage={displaySearchMessage}
                clearLabel={global?.clear || ''}
                searchLabel={global?.search || ''}
              />
              <RetailersAdditionalSelections
                settings={settings}
                data={content}
                radiusSelectChange={onChangeRadius}
                selectedRadius={radius}
                internationalSearchToggleChange={onChangeInternational}
                isRetailerSearchExpandable={true}
                isSearchInternational={international}
                radiusAlternatives={content.radiusAlternatives.map(
                  ({ radiusValue }) => `${radiusValue} km`,
                )}
              />
            </RetailerContainer>
          </GridChild>
        </Grid>
      </Section>

      <Section hide={tab !== TABS.search} zeroTop moreMarginBottom>
        <Grid columnGap="small">
          <GridChild
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'mobileMax', columns: 6 },
              { breakpoint: 'desktopSmall', columns: 5 },
            ]}
          >
            {loading
              ? (
                <Square>
                  <Loader small />
                </Square>
                )
              : (
                <FindRetailersList
                  stores={stores || []}
                  selectItem={selectItem}
                  selectedItem={selectedItem}
                  viewOnMap={content.viewOnMap || ''}
                  storeAlsoSells={content.storeAlsoSells || ''}
                  visitWebsite={content.visitWebSite || ''}
                />
                )}
          </GridChild>
          <GridChild
            padding="0 0 0 0px"
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'mobileMax', columns: 6, start: 7 },
            ]}
          >
            {geoLoading
              ? (
                <Square>
                  <Loader small />
                </Square>
                )
              : (
                <Map
                  center={coords}
                  stores={stores}
                  viewOnMap={content.viewOnMap || ''}
                  visitWebsiteLabel={content.visitWebSite || ''}
                  sticky
                />
                )}
          </GridChild>
        </Grid>
      </Section>

      <Section
        hide={tab !== TABS.onlineStores}
        style={{ minHeight: '15vh' }}
        noMarginTop
        moreMarginBottom
      >
        {loading
          ? (
            <Loader small />
            )
          : (
            <Grid
              columns={[
                { columns: 4 },
                { breakpoint: 'largeMobile', columns: 12 },
              ]}
            >
              <GridChild
                columnSpan={[
                  { columns: 4 },
                  { breakpoint: 'largeMobile', columns: 8, start: 3 },
                  { breakpoint: 'desktop', columns: 4, start: 5 },
                ]}
              >
                {onlineStores?.length > 0
                  ? (
                    <OnlineStoreList stores={onlineStores} />
                    )
                  : (
                    <H4 center>{content.noOnlineStoresFound}</H4>
                    )}
              </GridChild>
            </Grid>
            )}
      </Section>

      {content.seoVisibleTitle && content.seoVisibleDescription && (
        <VisibleSeoBlock
          title={content.seoVisibleTitle}
          description={content.seoVisibleDescription}
        />
      )}
    </>
  );
};
