import styled from 'styled-components';

import { colors, fontSizes, spacing } from '@hultafors/snickers/helpers';

export const RetailerTabsStyled = styled.div`
  margin-block-end: ${spacing.medium};

  .Tabs {
    display: flex;
    text-align: center;

    &.Center {
      justify-content: center;
    }
  }

  button {
    padding: 0;
  }

  a:hover {
    cursor: pointer;
  }

  .Tab {
    margin-inline-end: ${spacing.regular};

    p {
      margin: 0;
      color: ${colors.gray2};

      &::after {
        content: '';
        display: block;
        margin: 0 auto;
        width: 25%;
        border-bottom: 2px solid transparent;
      }
    }

    &.Active {
      margin-block-end: 0;
      height: 30px;

      p {
        color: ${colors.black};

        &::after {
          border-bottom: 2px solid black;
        }
      }
    }
  }

  .DetailInfo {
    list-style-type: none;
    padding: 0;

    li {
      font-size: ${fontSizes.body};
    }
  }
`;
