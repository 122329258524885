export const workwearGuideDefault = {
  ceCertificationTitle: '',
  ceNormsTitle: '',
  environmentTitle: '',
  featuresTitle: '',
  fitTitle: '',
  garmentTitle: '',
  genderTitle: '',
  headerIntro: '',
  headerText: '',
  occupationTitle: '',
  // TODO translation in DU
  occupationTitleJackets: 'What task will you perform in your jacket',
  occupationTitleGloves: 'What is your occupation',
  featuresTitleGloves: 'Are you in need of any specific feature',
  precisionTitle: 'What type of work will you use these gloves for',
  ceCertificationTitleProtectWork: 'What CE Certification are you looking for',
  environmentTitleGloves: 'What kind of environment will you use the glove',
  genderTitleProtectWork: 'Are you looking for female or male clothing',
  productNode: null,
  riskEnvironmentTitle: '',

  perfectMatch: 'Perfect',
  semiPerfectMatch: 'Excellent',
  lessPerfectMatch: 'Good',
  weRecommend: 'We recommend',
  personalFilter: 'Your personal filter',
  chooseCategoryTitle: 'Choose category',
  coldWetEnvironmentTitle: 'What kind of environment are you working in xx?',
};
