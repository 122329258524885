import dynamic from 'next/dynamic';

import {
  useGlobal,
  useSizeGuide,
  useWorkwearGuide,
} from '@hultafors/snickers/hooks';
import { HeroBlockFragment } from '@hultafors/snickers/types';

import {
  BigDescription,
  BigTitle,
  ButtonsContainer,
  ContentBoxBig,
  ContentBoxSmall,
  Description,
  DescriptionWrapper,
  GuideButton,
  GuideButtonContainer,
  HeroBlockStyled,
  HeroIcon,
  HeroOuter,
  HeroSubtitle,
  Inner,
  PreTitle,
  Title,
} from './hero-block.styled';
import NextSvg from './next.svg';

const DoubleColorText = dynamic(() =>
  import('../DoubleColorText/DoubleColorText').then(
    (module) => module.DoubleColorText,
  ),
);

const H1 = dynamic(() => import('../H1/H1').then((module) => module.H1));

const H2 = dynamic(() => import('../H2/H2').then((module) => module.H2));

const HeroImage = dynamic(() =>
  import('../HeroImage/HeroImage').then((module) => module.HeroImage),
);

const InfoBox = dynamic(() =>
  import('../InfoBox/InfoBox').then((module) => module.InfoBox),
);

const LinkButton = dynamic(() =>
  import('../LinkButton/LinkButton').then((module) => module.LinkButton),
);

const SearchBox = dynamic(() =>
  import('../SearchBox/SearchBox').then((module) => module.SearchBox),
);

const Grid = dynamic(() =>
  import('../Grid/Grid').then((module) => module.Grid),
);

const GridChild = dynamic(() =>
  import('../GridChild/GridChild').then((module) => module.GridChild),
);

export interface HeroBlockProps extends HeroBlockFragment {
  priority?: boolean;
  noGradient?: boolean;
  wide?: boolean;
}

export const HeroBlock: React.FC<HeroBlockProps> = ({
  alignContentLeft,
  boxColor,
  ctaLink,
  ctaText,
  secondCtaLink,
  secondCtaText,
  thirdCtaLink,
  thirdCtaText,
  description,
  doubleColorTitle,
  heroContentBoxBig,
  heroContentBoxSmall,
  heroIcon,
  guideIconImage,
  guideIconTitle,
  image,
  imageThin,
  mobileImage,
  preTitle,
  searchBox,
  sizeGuideButton,
  subTitle,
  tabletImage,
  title,
  workWearButton,
  priority,
  noGradient,
  wide,
  thin,
  limitWidth,
}) => {
  const { global } = useGlobal();
  const { toggle: toggleWorkwearGuide } = useWorkwearGuide();
  const { toggle: toggleSizeGuide } = useSizeGuide();
  const onClickGuide = () => {
    if (sizeGuideButton && !workWearButton) {
      toggleSizeGuide();
    }
    if (!sizeGuideButton && workWearButton) {
      toggleWorkwearGuide();
    }
  };
  let desktopImage = image || undefined;
  if (thin) {
    desktopImage = imageThin || undefined;
  }
  let TitleComponent = H2;
  if (priority) {
    TitleComponent = H1;
  }

  if (!desktopImage && !title) {
    return null;
  }

  if (!priority && limitWidth) {
    return (
      <HeroOuter
        $marginBottom={!heroContentBoxSmall && !heroContentBoxBig}
        data-test-id="HeroBlock"
      >
        <Grid>
          <GridChild
            columnSpan={[
              { columns: 5 },
              { breakpoint: 'mobileMax', columns: 12 },
              { breakpoint: 'desktop', columns: 10, start: 2 },
            ]}
          >
            <HeroBlockStyled
              $image={image ?? undefined}
              $noGradient={noGradient}
              $thin={thin}
            >
              <HeroImage
                mobile={mobileImage ?? undefined}
                tablet={tabletImage ?? undefined}
                desktop={desktopImage}
                priority={priority}
              />
              <Inner $left={alignContentLeft}>
                {preTitle && <PreTitle>{preTitle}</PreTitle>}
                {!preTitle && !alignContentLeft && heroIcon && (
                  <HeroIcon
                    aria-hidden={true}
                    focusable={false}
                    width={23}
                    height={56}
                  />
                )}
                {!heroContentBoxBig && title && doubleColorTitle && (
                  <DoubleColorText text={title} />
                )}
                {!heroContentBoxBig && title && !doubleColorTitle && (
                  <Title
                    $shadow={!!image}
                    $withIcon={alignContentLeft && !!heroIcon}
                    $wide={wide}
                    as={TitleComponent}
                  >
                    {title}
                  </Title>
                )}

                {subTitle && (
                  <HeroSubtitle $image={image ?? undefined}>
                    {subTitle}
                  </HeroSubtitle>
                )}
                {description && !heroContentBoxSmall && !heroContentBoxBig && (
                  <DescriptionWrapper
                    $withLine={alignContentLeft && !!heroIcon}
                  >
                    <Description
                      $shadow={!!image}
                      $withLine={alignContentLeft && !!heroIcon}
                    >
                      {description}
                    </Description>
                  </DescriptionWrapper>
                )}
                {ctaLink && ctaText && (
                  <ButtonsContainer>
                    <LinkButton large text={ctaText} url={ctaLink} />
                    {secondCtaLink && secondCtaText && <LinkButton large text={secondCtaText} url={secondCtaLink} />}
                    {thirdCtaLink && thirdCtaText && <LinkButton large text={thirdCtaText} url={thirdCtaLink} />}
                  </ButtonsContainer>
                )}
                {searchBox && <SearchBox transparent wide />}
              </Inner>
            </HeroBlockStyled>
            {heroContentBoxSmall && (
              <ContentBoxSmall $boxColor={boxColor?.hex}>
                {description}
              </ContentBoxSmall>
            )}
            {heroContentBoxBig && (
              <ContentBoxBig>
                {sizeGuideButton && !workWearButton && (
                  <GuideButtonContainer>
                    <GuideButton onClick={onClickGuide}>
                      {global.sizeGuideButtonLabel}
                      <NextSvg aria-hidden={true} focusable={false} />
                    </GuideButton>
                  </GuideButtonContainer>
                )}
                {!sizeGuideButton && workWearButton && (
                  <GuideButtonContainer>
                    <GuideButton onClick={onClickGuide}>
                      {global.workwearGuideButtonLabel}
                      <NextSvg aria-hidden={true} focusable={false} />
                    </GuideButton>
                  </GuideButtonContainer>
                )}
                <BigTitle>{title}</BigTitle>
                {subTitle && (
                  <HeroSubtitle $image={image ?? undefined}>
                    {subTitle}
                  </HeroSubtitle>
                )}
                {guideIconImage?.url && (
                  <div>
                    <InfoBox
                      title={guideIconTitle ?? ''}
                      icon={guideIconImage.url}
                    />
                  </div>
                )}
                <BigDescription>{description}</BigDescription>
              </ContentBoxBig>
            )}
          </GridChild>
        </Grid>
      </HeroOuter>
    );
  } else
    return (
      <HeroOuter
        $marginBottom={!heroContentBoxSmall && !heroContentBoxBig}
        data-test-id="HeroBlock"
      >
        <HeroBlockStyled
          $image={image ?? undefined}
          $noGradient={noGradient}
          $thin={thin}
        >
          <HeroImage
            mobile={mobileImage ?? undefined}
            tablet={tabletImage ?? undefined}
            desktop={desktopImage}
            priority={priority}
          />
          <Inner $left={alignContentLeft}>
            {preTitle && <PreTitle>{preTitle}</PreTitle>}
            {!preTitle && !alignContentLeft && heroIcon && (
              <HeroIcon
                aria-hidden={true}
                focusable={false}
                width={23}
                height={56}
              />
            )}
            {!heroContentBoxBig && title && doubleColorTitle && (
              <DoubleColorText text={title} />
            )}
            {!heroContentBoxBig && title && !doubleColorTitle && (
              <Title
                $shadow={!!image}
                $withIcon={alignContentLeft && !!heroIcon}
                $wide={wide}
                as={TitleComponent}
              >
                {title}
              </Title>
            )}

            {subTitle && (
              <HeroSubtitle $image={image ?? undefined}>
                {subTitle}
              </HeroSubtitle>
            )}
            {description && !heroContentBoxSmall && !heroContentBoxBig && (
              <DescriptionWrapper $withLine={alignContentLeft && !!heroIcon}>
                <Description
                  $shadow={!!image}
                  $withLine={alignContentLeft && !!heroIcon}
                >
                  {description}
                </Description>
              </DescriptionWrapper>
            )}
            {ctaLink && ctaText && (
              <ButtonsContainer>
                <LinkButton large text={ctaText} url={ctaLink} />
                {secondCtaLink && secondCtaText && <LinkButton large text={secondCtaText} url={secondCtaLink} />}
                {thirdCtaLink && thirdCtaText && <LinkButton large text={thirdCtaText} url={thirdCtaLink} />}
              </ButtonsContainer>
            )}

            {searchBox && <SearchBox transparent wide />}
          </Inner>
        </HeroBlockStyled>
        {heroContentBoxSmall && (
          <ContentBoxSmall $boxColor={boxColor?.hex}>
            {description}
          </ContentBoxSmall>
        )}
        {heroContentBoxBig && (
          <ContentBoxBig>
            {sizeGuideButton && !workWearButton && (
              <GuideButtonContainer>
                <GuideButton onClick={onClickGuide}>
                  {global.sizeGuideButtonLabel}
                  <NextSvg aria-hidden={true} focusable={false} />
                </GuideButton>
              </GuideButtonContainer>
            )}
            {!sizeGuideButton && workWearButton && (
              <GuideButtonContainer>
                <GuideButton onClick={onClickGuide}>
                  {global.workwearGuideButtonLabel}
                  <NextSvg aria-hidden={true} focusable={false} />
                </GuideButton>
              </GuideButtonContainer>
            )}
            <BigTitle>{title}</BigTitle>
            {subTitle && (
              <HeroSubtitle $image={image ?? undefined}>
                {subTitle}
              </HeroSubtitle>
            )}
            {guideIconImage?.url && (
              <div>
                <InfoBox
                  title={guideIconTitle ?? ''}
                  icon={guideIconImage.url}
                />
              </div>
            )}
            <BigDescription>{description}</BigDescription>
          </ContentBoxBig>
        )}
      </HeroOuter>
    );
};
