import styled, { css } from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/snickers/helpers';

import { Input } from '../Input/Input';

interface TallProps {
  $tall?: boolean;
}

interface TransparentProps {
  $transparent?: boolean;
}

interface ShowProps {
  $show?: boolean;
}

export const SearchInputStyled = styled.span<
  TallProps & TransparentProps & ShowProps
>`
  width: 100%;
`;

export const SearchInputFormWrapper = styled.div<TallProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ $tall }) => ($tall ? '150px' : 'auto')};
  width: 500px;
`;

export const SearchInputForm = styled.form`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const SearchInput = styled(Input)<TransparentProps>`
  width: 100%;
  max-width: 500px;
  margin: 0;

  input[type='text'] {
    margin: 0;
    padding: 0 40px 0 20px;
    border-radius: 24px;
    font-size: 16px;
    line-height: 3rem;
    color: ${({ $transparent }) =>
      $transparent ? colors.white : colors.workwearBlack};
    background-color: ${({ $transparent }) =>
      $transparent ? 'transparent' : colors.white};
    border: ${({ $transparent }) =>
      $transparent ? '1px solid ' + colors.white : '1px solid ' + colors.gray3};
  }

  input:placeholder-shown {
    border-color: ${({ $transparent }) =>
      $transparent ? colors.gray4 : colors.gray3};
  }

  input::placeholder {
    color: ${({ $transparent }) =>
      $transparent ? colors.gray3 : colors.gray2};
  }
`;

const buttonStyles = css<TransparentProps>`
  appearance: none;
  border-radius: 50%;
  background-color: ${({ $transparent }) =>
    $transparent ? 'transparent' : colors.gray4};
  border: ${({ $transparent }) =>
    $transparent ? '1px solid ' + colors.white : 'none'};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${spacing.regular};
  width: ${spacing.regular};
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const SearchInputChildren = styled.div`
  margin-block-end: ${spacing.small};
`;

export const ResultText = styled.p`
  font-family: ${fontFamilies.faktPro};
  line-height: ${lineHeights.bodyMobile};
  margin-block: ${spacing.regular} 0;
  font-weight: ${fontWeights.normal};
  font-size: ${fontSizes.body};
  text-align: center;

  &:empty {
    display: none;
  }
`;

export const ClearButton = styled.button<ShowProps & TransparentProps>`
  ${buttonStyles};
  right: 48px;
  transition: opacity 0.1s ease-out;
  opacity: ${({ $show }) => ($show ? '1' : '0')};
  pointer-events: ${({ $show }) => ($show ? 'inherit' : 'none')};

  svg {
    width: 16px;
    height: 16px;

    g {
      fill: ${({ $transparent }) =>
        $transparent ? colors.white : colors.workwearBlack};
    }
  }
`;

export const SearchButton = styled.button<TransparentProps>`
  ${buttonStyles};
  right: 8px;

  svg {
    width: 16px;
    height: 16px;

    g {
      fill: ${({ $transparent }) =>
        $transparent ? colors.white : colors.workwearBlack};
    }
  }

  &:disabled {
    cursor: not-allowed;

    svg {
      opacity: 0.5;
    }
  }
`;
