import dynamic from 'next/dynamic';

import { ImageFragment } from '@hultafors/snickers/types';

import { Button } from '../Button/Button';
import { PlugIcon } from '../PlugIcon/PlugIcon';

import {
  CampaignPlugImage,
  CampaignPlugStyled,
  Content,
  Heading,
  SmallText,
  StyledLink,
} from './CampaignPlug.styled';

const Picture = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.Picture),
);

export interface CampaignPlugProps {
  title?: string;
  intro?: string;
  image?: ImageFragment;
  url?: string;
  buttonText?: string;
}

export const CampaignPlug: React.FC<CampaignPlugProps> = ({
  title = '',
  url = '',
  image,
  intro = '',
  buttonText = '',
}) => (
  <CampaignPlugStyled>
    <StyledLink href={url}>
      <CampaignPlugImage>
        {image?.responsiveImage?.src && (
          <Picture
            url={image.responsiveImage.src}
            alt={image.alt || ''}
            params={{ w: '600', h: '454' }}
            base64={image.responsiveImage?.base64}
            sources={[
              {
                media: '(min-width:1180px)',
                images: [
                  { params: { w: '1180', h: '600' }, descriptor: '1x' },
                  {
                    params: { w: '2360', h: '1200', q: '50' },
                    descriptor: '2x',
                  },
                ],
              },
              {
                media: '(min-width:768px)',
                images: [
                  { params: { w: '768', h: '460' }, descriptor: '1x' },
                  {
                    params: { w: '1536', h: '920', q: '50' },
                    descriptor: '2x',
                  },
                ],
              },
            ]}
          />
        )}
      </CampaignPlugImage>
      <Content image={image}>
        <PlugIcon />
        <Heading>{title}</Heading>
        <SmallText>{intro}</SmallText>
        <Button className="plug-button" yellow>
          {buttonText}
        </Button>
      </Content>
    </StyledLink>
  </CampaignPlugStyled>
);
