import styled from 'styled-components';

import { colors } from '@hultafors/snickers/helpers';

export const MobileFilterWrapperStyled = styled.div`
  padding: 20px;

  .SelectedFilters {
    position: relative;
    padding: 20px 30px 20px 10px;
    margin-block-end: 24px;
    background-color: ${colors.gray5};

    h4 {
      margin: 0 0 16px;
    }

    .ClearFilters {
      position: absolute;
      top: 6px;
      right: 10px;
      display: flex;
      width: auto;
      height: 48px;
      padding: 0;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 0;
      background: none;
      font-size: 14px;

      img {
        margin-left: 8px;
      }
    }

    .ApplyFilters {
      margin-block-start: 1rem;
    }

    span {
      font-size: 14px;
      line-height: 24px;
      color: ${colors.workwearBlack};

      &:not(:last-of-type)::after {
        content: ', ';
      }
    }
  }
`;
