import styled, { css } from 'styled-components';

import { colors } from '@hultafors/snickers/helpers';

interface DropdownStyledProps {
  $dark?: boolean;
  $disabled?: boolean;
}

export const DropdownStyled = styled.ul<DropdownStyledProps>`
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .DropdownLabel {
    padding: 10px 24px 10px 12px;
    font-size: 14px;
    letter-spacing: 0.5px;
    cursor: pointer;
    color: ${({ $dark }) => ($dark ? colors.white : colors.workwearBlack)};
    transition: color 0.2s linear;
    appearance: none;
    border: 0;
    background-color: transparent;

    &:hover {
      color: ${({ $dark }) => ($dark ? colors.grayBtnHover : colors.gray1)};
    }

    &::after {
      content: '';
      height: 12px;
      width: 12px;
      background-image: ${({ $dark }) =>
        $dark
          ? "url('/assets/gfx/chevron-white.svg')"
          : "url('/assets/gfx/chevron.svg')"};
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
      transition: transform 0.1s linear;
    }

    &.isOpen::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .DropdownList {
    position: absolute;
    background: ${colors.white};
    width: auto;
    min-width: 120px;
    left: 0;
    top: 95%;
    padding: 16px 12px;
    box-shadow: 0 2px 10px 0 ${colors.boxshadowTransparent};
    transition: opacity 0.1s cubic-bezier(0, 0, 0.38, 0.9),
      max-height 0.5s cubic-bezier(0, 0, 0.38, 0.9),
      max-height 0.5s cubic-bezier(0, 0, 0.38, 0.9);
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    z-index: 11;

    &.open {
      opacity: 1;
      overflow: auto;
      max-height: 15rem;
    }
  }

  .DropdownListItem {
    padding: 1px;
    text-align: left;
    color: ${colors.workwearBlack};
    font-size: 14px;
    transition: color 0.1s linear;
    cursor: pointer;

    &:not(:last-of-type) {
      margin-block-end: 16px;
    }

    &:hover {
      color: ${colors.gray1};
    }
  }

  ${({ $disabled }) =>
    $disabled
    && css`
      opacity: 0.3;
      pointer-events: none;
    `}
`;
