import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/snickers/helpers';

const ALERT_STATUS = {
  error: 'error',
  info: 'info',
  success: 'success',
  warning: 'warning',
} as const;
export type AlertStatus = keyof typeof ALERT_STATUS;

const ALERT_SIZE = {
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const;
export type AlertSize = keyof typeof ALERT_SIZE;

export type AlertPlacement = 'top' | 'bottom';

interface StyledAlertProps {
  $size?: AlertSize;
  $status?: AlertStatus;
  $position?: CSSProperties['position'];
  $placement?: AlertPlacement;
}

const getStatusStyle = ({ $status }: StyledAlertProps) =>
  ({
    info: css`
      background: ${colors.white};
      color: ${colors.black};

      button {
        background: ${colors.black};
        color: ${colors.white};
      }
    `,
    warning: css`
      background: ${colors.warning};
      color: ${colors.black};

      button {
        background: ${colors.black};
        color: ${colors.white};
      }
    `,
    error: css`
      background: ${colors.workwearYellow2};
      color: ${colors.white};

      button {
        background: ${colors.white};
        color: ${colors.black};
      }
    `,
    success: css`
      background: ${colors.flexiworkGreen};
      color: ${colors.black};

      button {
        background: ${colors.black};
        color: ${colors.white};
      }
    `,
  }[$status || 'info']);

const getSizeStyle = ({ $size }: StyledAlertProps) =>
  ({
    small: css({
      padding: spacing.tiny,
    }),
    medium: css({
      padding: spacing.small,
    }),
    large: css({
      padding: spacing.medium,
    }),
  }[$size || 'medium']);

const getPositionStyle = ({ $position, $placement }: StyledAlertProps) =>
  $position === 'absolute'
    ? css`
        position: absolute;
        inset: ${$placement === 'top' ? 0 : 'auto'} 0
          ${$placement === 'bottom' ? 0 : 'auto'} 0;
        width: 100%;
        margin: 0 auto;
      `
    : $position === 'fixed'
      ? css`
        position: fixed;
        inset: ${$placement === 'top' ? 0 : 'auto'} 0
          ${$placement === 'bottom' ? 0 : 'auto'} 0;
        width: 100%;
        margin: 0 auto;
      `
      : css`
        position: ${$position};
      `;

export const StyledAlert = styled.div<StyledAlertProps>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: ${fontSizes.s};
  line-height: ${lineHeights.s};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    align-items: center;
  }

  button {
    appearance: none;
    padding: ${spacing.tiny} ${spacing.small};
    border: none;
    font-size: ${fontSizes.s};
    line-height: ${lineHeights.s};
    min-height: 0;

    &:hover {
      background-color: ${colors.black};
    }

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      &:last-child {
        margin: 0 0 0 0.25em;
      }
    }
  }

  margin: ${spacing.small} auto;
  ${getPositionStyle};
  ${getStatusStyle};
  ${getSizeStyle};
`;
