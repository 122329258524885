export const fontWeights = {
  normal: 300,
  medium: 400,
  thick: 500,
  thicker: 600,
  bold: 700,
  black: 800,
} as const;

export type FontWeights = keyof typeof fontWeights;
