import { SizeItem, WaistList } from '@hultafors/snickers/types';

export const trousersWomanWaists: WaistList = {
  body: [
    { inch: { min: 27, max: 28 }, cm: { min: 68, max: 71 } },
    { inch: { min: 29, max: 29 }, cm: { min: 72, max: 75 } },
    { inch: { min: 30, max: 31 }, cm: { min: 76, max: 79 } },
    { inch: { min: 32, max: 32 }, cm: { min: 80, max: 83 } },
    { inch: { min: 33, max: 34 }, cm: { min: 84, max: 87 } },
    { inch: { min: 35, max: 36 }, cm: { min: 88, max: 91 } },
    { inch: { min: 37, max: 37 }, cm: { min: 92, max: 95 } },
    { inch: { min: 38, max: 39 }, cm: { min: 96, max: 99 } },
    { inch: { min: 40, max: 42 }, cm: { min: 100, max: 107 } },
    { inch: { min: 43, max: 45 }, cm: { min: 108, max: 115 } },
    { inch: { min: 46, max: 48 }, cm: { min: 116, max: 123 } },
    { inch: { min: 49, max: 51 }, cm: { min: 124, max: 131 } },
    { inch: { min: 52, max: 54 }, cm: { min: 132, max: 139 } },
    { inch: { min: 55, max: 58 }, cm: { min: 140, max: 147 } },
    { inch: { min: 59, max: 61 }, cm: { min: 148, max: 155 } },
    { inch: { min: 62, max: 64 }, cm: { min: 156, max: 163 } },
    { inch: { min: 65, max: 67 }, cm: { min: 164, max: 171 } },
    { inch: { min: 68, max: 70 }, cm: { min: 172, max: 179 } },
  ],
  garment: [
    { inch: { min: 26, max: 27 }, cm: { min: 65, max: 68 } },
    { inch: { min: 28, max: 28 }, cm: { min: 69, max: 72 } },
    { inch: { min: 29, max: 30 }, cm: { min: 73, max: 76 } },
    { inch: { min: 31, max: 31 }, cm: { min: 77, max: 80 } },
    { inch: { min: 32, max: 33 }, cm: { min: 81, max: 84 } },
    { inch: { min: 34, max: 35 }, cm: { min: 85, max: 88 } },
    { inch: { min: 36, max: 36 }, cm: { min: 89, max: 92 } },
    { inch: { min: 37, max: 38 }, cm: { min: 93, max: 96 } },
    { inch: { min: 39, max: 40 }, cm: { min: 97, max: 102 } },
    { inch: { min: 41, max: 43 }, cm: { min: 103, max: 108 } },
    { inch: { min: 44, max: 45 }, cm: { min: 109, max: 114 } },
    { inch: { min: 46, max: 47 }, cm: { min: 115, max: 120 } },
    { inch: { min: 48, max: 50 }, cm: { min: 121, max: 126 } },
    { inch: { min: 51, max: 52 }, cm: { min: 127, max: 132 } },
    { inch: { min: 53, max: 54 }, cm: { min: 133, max: 138 } },
    { inch: { min: 55, max: 57 }, cm: { min: 139, max: 144 } },
    { inch: { min: 58, max: 59 }, cm: { min: 145, max: 150 } },
    { inch: { min: 60, max: 61 }, cm: { min: 151, max: 156 } },
  ],
};

export const trousersWoman: SizeItem[] = [
  {
    id: 'trousers_w_1',
    name: 'Extra Short',
    leg: {
      garment: {
        cm: { min: 65, max: 70 },
        inch: { min: 26, max: 27 },
      },
      body: {
        cm: { min: 65, max: 70 },
        inch: { min: 26, max: 27 },
      },
    },
    sizes: [
      {
        size: 116,
      },
      {
        size: 117,
      },
      {
        size: 118,
      },
      {
        size: 119,
      },
      {
        size: 120,
      },
      {
        size: 121,
      },
      {
        size: 122,
      },
      {
        size: 123,
      },
      {
        size: 124,
      },
      {
        size: 125,
      },
      {
        size: 126,
      },
      {
        size: 127,
      },
      {
        size: 128,
      },
      {
        size: 129,
      },
      {
        size: 130,
      },
      {
        size: 131,
      },
      {
        size: 132,
      },
      {
        size: 133,
      },
    ],
  },
  {
    id: 'trousers_w_2',
    name: 'Short',
    leg: {
      garment: {
        cm: { min: 71, max: 76 },
        inch: { min: 28, max: 30 },
      },
      body: {
        cm: { min: 71, max: 76 },
        inch: { min: 28, max: 30 },
      },
    },
    sizes: [
      {
        size: 16,
      },
      {
        size: 17,
      },
      {
        size: 18,
      },
      {
        size: 19,
      },
      {
        size: 20,
      },
      {
        size: 21,
      },
      {
        size: 22,
      },
      {
        size: 23,
      },
      {
        size: 24,
      },
      {
        size: 25,
      },
      {
        size: 26,
      },
      {
        size: 27,
      },
      {
        size: 628,
      },
      {
        size: 629,
      },
      {
        size: 630,
      },
      {
        size: 631,
      },
      {
        size: 632,
      },
      {
        size: 633,
      },
    ],
  },
  {
    id: 'trousers_w_3',
    name: 'Regular',
    leg: {
      garment: {
        cm: { min: 77, max: 82 },
        inch: { min: 31, max: 32 },
      },
      body: {
        cm: { min: 77, max: 82 },
        inch: { min: 31, max: 31 },
      },
    },
    sizes: [
      {
        size: 32,
      },
      {
        size: 34,
      },
      {
        size: 36,
      },
      {
        size: 38,
      },
      {
        size: 40,
      },
      {
        size: 42,
      },
      {
        size: 44,
      },
      {
        size: 46,
      },
      {
        size: 48,
      },
      {
        size: 50,
      },
      {
        size: 52,
      },
      {
        size: 54,
      },
      {
        size: 756,
      },
      {
        size: 758,
      },
      {
        size: 760,
      },
      {
        size: 762,
      },
      {
        size: 764,
      },
      {
        size: 766,
      },
    ],
  },
  {
    id: 'trousers_w_4',
    name: 'Long',
    leg: {
      garment: { cm: { min: 83, max: 88 }, inch: { min: 33, max: 34 } },
      body: { cm: { min: 83, max: 88 }, inch: { min: 33, max: 34 } },
    },
    sizes: [
      {
        size: 64,
      },
      {
        size: 68,
      },
      {
        size: 72,
      },
      {
        size: 76,
      },
      {
        size: 80,
      },
      {
        size: 84,
      },
      {
        size: 88,
      },
      {
        size: 92,
      },
      {
        size: 96,
      },
      {
        size: 100,
      },
      {
        size: 104,
      },
      {
        size: 108,
      },
      {
        size: 112,
      },
      {
        size: 816,
      },
      {
        size: 820,
      },
      {
        size: 824,
      },
      {
        size: 828,
      },
      {
        size: 832,
      },
    ],
  },
  {
    id: 'trousers_w_5',
    name: 'Extra Long',
    leg: {
      garment: { cm: { min: 89, max: 94 }, inch: { min: 35, max: 37 } },
      body: {
        cm: { min: 89, max: 94 },
        inch: { min: 35, max: 37 },
      },
    },
    sizes: [
      {
        size: 164,
      },
      {
        size: 168,
      },
      {
        size: 172,
      },
      {
        size: 176,
      },
      {
        size: 180,
      },
      {
        size: 184,
      },
      {
        size: 188,
      },
      {
        size: 192,
      },
      {
        size: 196,
      },
      {
        size: 200,
      },
      {
        size: 204,
      },
      {
        size: 208,
      },
      {
        size: 212,
      },
      {
        size: 216,
      },
      {
        size: 220,
      },
      {
        size: 224,
      },
      {
        size: 228,
      },
      {
        size: 232,
      },
    ],
  },
];
