import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes } from '@hultafors/snickers/helpers';

import { H2 } from '../../../H2/H2';

interface MuteButtonProps {
  $isMuted?: boolean;
}

export const VideoBlockStyled = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - var(--header-height));
  background-color: ${colors.gray4};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  --dialog: none; /* Hide the error dialog */
  --media-object-fit: cover;
  --controls: none;

  a {
    z-index: 1;
  }
`;

/* url image is PLACEHOLDER */
export const MuteButton = styled.button<MuteButtonProps>`
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  background: none;
  border: none;
  background-image: ${({ $isMuted }) =>
    $isMuted
      ? "url('/assets/gfx/video_sound_muted.svg')"
      : "url('/assets/gfx/video_sound_active.svg')"};

  &:hover {
    background-image: ${({ $isMuted }) =>
      $isMuted
        ? "url('/assets/gfx/video_sound_muted_hover.svg')"
        : "url('/assets/gfx/video_sound_active_hover.svg')"};
  }

  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
`;

export const VideoControls = styled.div``;

export const Outer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  video {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

export const Heading = styled(H2)`
  text-transform: uppercase;
  text-shadow: 0 0 1rem ${colors.workwearBlack};
  text-align: center;
  max-width: 24ch;
  margin-block: 0 1rem;
  color: ${colors.white};
  font-size: ${fontSizes.h1Mobile};
  z-index: 1;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    font-size: ${fontSizes.h1};
  }
`;
