import styled, { css } from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/snickers/helpers';

export type ParagraphSize = 'regular' | 'small' | 'intro' | 'contentMobile';
export type ParagraphStyleType = 'ingressDesktop' | 'contentDesktop';

interface ParagraphStyledProps {
  $size?: ParagraphSize;
  $styleType?: ParagraphStyleType;
  $center?: boolean;
}

const fontStyle = ({ $styleType }: ParagraphStyledProps) => {
  switch ($styleType) {
    case 'ingressDesktop':
      return css`
        margin: 0;
        font-size: ${fontSizes.l};
        line-height: ${lineHeights.ingressDesktop};
        font-weight: ${fontWeights.medium};
        font-family: ${fontFamilies.faktPro};
      `;
    case 'contentDesktop':
      return css`
        margin: 0;
        font-size: ${fontSizes.body};
        line-height: ${lineHeights.contentDesktop};
        font-weight: ${fontWeights.normal};
        font-family: ${fontFamilies.faktPro};
        letter-spacing: 0.02rem;
      `;
    default:
      return css`
        margin: 0;
        font-size: ${fontSizes.l};
        line-height: ${lineHeights.ingressDesktop};
        font-family: ${fontFamilies.faktPro};
      `;
  }
};

export const ParagraphStyled = styled.p<ParagraphStyledProps>`
  font-family: ${fontFamilies.faktPro};
  font-size: ${({ $size }) => ($size === 'small' ? '12px' : fontSizes.body)};
  line-height: ${lineHeights.bodyMobile};
  color: ${colors.workwearBlack};
  text-align: ${({ $center }) => ($center ? 'center' : '')};

  span,
  p {
    font-size: ${({ $size }) => ($size === 'small' ? '12px' : fontSizes.body)};
  }

  ${({ $size }) =>
    $size === 'intro'
    && css`
      font-size: ${fontSizes.l};
      line-height: 26px;
      font-weight: ${fontWeights.medium};
      margin-block-end: 20px;
    `}

  p {
    ${({ $size }) =>
      $size === 'contentMobile'
      && css`
        font-size: ${fontSizes.contentMobile};
        line-height: ${lineHeights.contentMobile};
        font-weight: ${fontWeights.normal};
        letter-spacing: 0.02rem;
      `}
  }

  &.ProductTechnicalDescription {
    max-width: 60ch;

    &:first-of-type {
      margin-block-start: 0;
    }
  }

  ${fontStyle}
`;
