import { UnitsMinMax } from '@hultafors/shared/types';

import {
  MEASURE_TYPES,
  SIZE_GUIDE_STEPS,
} from '@hultafors/snickers/product-helpers';
import {
  MinMaxSizes,
  SizeSelection,
  SizeValidation,
  WaistList,
} from '@hultafors/snickers/types';

import SizeGuideSvg from '../../../svg/size-guide.svg';
import { Button } from '../../Button/Button';
import { GuideHero } from '../../GuideHero/GuideHero';
import { H4 } from '../../H4/H4';
import { Input } from '../../Input/Input';
import { SizeGuideStyled } from '../SizeGuide.styled';

export interface MeasureBodyProps {
  toggleGuide(...args: any[]): unknown;
  changeStep(...args: any[]): unknown;
  steps: typeof SIZE_GUIDE_STEPS;
  selection: SizeSelection;
  sku?: string;
  changeSize(
    e: React.ChangeEvent<HTMLInputElement>,
    type: keyof WaistList,
    unit: keyof UnitsMinMax
  ): void;
  units: any[];
  changeUnit(...args: any[]): unknown;
  title?: string;
  introText?: string;
  backText?: string;
  waistText?: string;
  insideLegText?: string;
  yourMeasurementsText?: string;
  showMySize?: string;
  validation: SizeValidation;
  validationMsg: string;
  minMax: MinMaxSizes;
}

export const MeasureBody: React.FC<MeasureBodyProps> = ({
  minMax,
  showMySize = 'Show my size',
  backText,
  changeSize,
  changeStep,
  changeUnit,
  insideLegText = 'Inside leg',
  introText,
  selection,
  sku,
  steps,
  title,
  toggleGuide,
  units,
  validation,
  validationMsg,
  waistText = 'Waist',
  yourMeasurementsText,
}) => {
  const validateScreen = () => {
    return selection.waistSize && selection.legSize ? false : true;
  };

  const prepareValidationError = (type: keyof MinMaxSizes) => {
    if (type === 'waist') {
      if (validation.waistError) {
        return `${validationMsg} ${minMax[type][selection.unit].min}-${
          minMax[type][selection.unit].max
        }`;
      }
    } else {
      if (validation.legsError) {
        return `${validationMsg} ${minMax[type][selection.unit].min}-${
          minMax[type][selection.unit].max
        }`;
      }
    }
    return '';
  };

  const onChangeSize: React.ChangeEventHandler<HTMLInputElement> = (event) =>
    changeSize(
      event,
      MEASURE_TYPES['BODY'] as keyof WaistList,
      selection.unit,
    );

  return (
    <SizeGuideStyled>
      <GuideHero
        title={title}
        introText={introText}
        isBig={false}
        backText={backText}
        currentStep={1}
        changeStep={changeStep}
        guideType="size"
      />

      <div className="MeasurementImageWrapper">
        <SizeGuideSvg aria-hidden={true} focusable={false} />
      </div>

      <div className="ContentWrapper">
        <div className="TextAndSelectWrapper">
          <H4 center>{yourMeasurementsText}</H4>
          <div className="SelectWrapper">
            <select
              name="units"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                changeUnit(e, MEASURE_TYPES['BODY'])}
              value={selection.unit}
            >
              {units.map((item, index) => {
                return (
                  <option key={`Unit-${index}`} value={item.key}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="ChangeSize">
          <div className="Inputs">
            <Input
              onChange={onChangeSize}
              value={selection.waistSize}
              type="number"
              inputMode="numeric"
              label={'A. ' + waistText}
              placeholder={waistText}
              name="waistSize"
              className={validation.waistError ? 'Error' : ''}
              errorMessage={prepareValidationError('waist')}
              min={0}
            />
            <Input
              onChange={onChangeSize}
              value={selection.legSize}
              type="number"
              inputMode="numeric"
              label={'B. ' + insideLegText}
              placeholder={insideLegText}
              name="legsSize"
              className={validation.legsError ? 'Error' : ''}
              errorMessage={prepareValidationError('legs')}
              min={0}
            />
          </div>
        </div>
      </div>

      <div className="Summary">
        <Button
          disabled={validateScreen()}
          onClick={() => changeStep(steps['RESULT'])}
        >
          {showMySize}
        </Button>
      </div>
    </SizeGuideStyled>
  );
};
