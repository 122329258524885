import styled, { CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontWeights } from '@hultafors/snickers/helpers';

interface PersonStyledProps {
  $marginTopMobile?: CSSProperties['marginTop'];
}

export const PersonStyled = styled.div<PersonStyledProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 0;
  border-top: 1px solid ${colors.gray4};
  border-bottom: 1px solid ${colors.gray4};
  margin-block: ${({ $marginTopMobile }) =>
      $marginTopMobile ? $marginTopMobile + 'px' : '0'}
    32px;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block-end: 48px;
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    margin-block-end: 48px;
  }
`;
export const TextWrapper = styled.div`
  margin-left: 25px;

  p {
    font-size: 14px;
    font-weight: ${fontWeights.medium};
    margin: 0;

    &.Label {
      color: ${colors.gray2};
      margin-block-end: 8px;
    }

    &.Name {
      &::before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        height: 1px;
        width: 16px;
        background-color: ${colors.workwearBlack};
        margin-inline-end: 8px;
      }
    }
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 64px;
  height: 64px;

  img {
    border-radius: 50%;
  }
`;
