import Link from 'next/link';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  sizing,
  spacing,
} from '@hultafors/snickers/helpers';

import { RouterLink } from '../RouterLink/RouterLink';

export const FooterStyled = styled.footer`
  margin-block-start: auto;
  width: 100%;
  background-color: ${colors.workwearBlack};
  color: ${colors.white};
  font-size: ${fontSizes.m};
  margin-top: ${sizing.large};

  .Grid {
    position: relative;
  }

  section {
    padding: 20px 44px;
    margin: 0;

    @media screen and (min-width: ${breakpoints.desktop}) {
      padding: 48px 4em 10px;
    }
  }

  .Dropdown {
    display: flex;
    align-items: center;
    margin-inline-end: 16px;

    @media screen and (min-width: ${breakpoints.mediumMobile}) {
      margin-inline-end: 24px;
    }

    @media screen and (min-width: ${breakpoints.largeMobile}) {
      margin-inline-end: 32px;
    }

    select {
      appearance: none;
      border: none;
      background: none;
      padding-inline-end: 20px;
      direction: rtl;
      font-size: 14px;
      color: ${colors.white};
      letter-spacing: 0.5px;
      cursor: pointer;
      outline: none;

      option {
        background-color: ${colors.white};
        color: ${colors.black};
      }

      &:hover {
        color: ${colors.grayBtnHover};
      }
    }

    &::after {
      content: '';
      height: 12px;
      width: 12px;
      background-image: url('/assets/gfx/chevron-white.svg');
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  a {
    text-decoration: none;
  }

  a.Underline {
    text-decoration: underline;
  }

  span,
  a,
  p,
  span a {
    color: ${colors.white};
    font-size: 14px;
    letter-spacing: 0;
  }

  a:hover {
    color: ${colors.grayBtnHover};
  }
`;

export const UnderlinedLink = styled(RouterLink)`
  text-decoration: underline;
`;

export const MiddleSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-block-end: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    flex-direction: row;
  }
`;

export const ContactLinkRow = styled.p`
  font-family: ${fontFamilies.faktPro};
  font-size: ${fontSizes.body};
  line-height: ${lineHeights.bodyMobile};
  margin-block: ${sizing.small} 0;
  font-weight: ${fontWeights.normal};
`;

export const IntroText = styled.p`
  font-family: ${fontFamilies.faktPro};
  font-size: ${fontSizes.body};
  line-height: ${lineHeights.bodyMobile};
  margin-block: 0 ${spacing.regular};
`;

export const Content = styled.div`
  flex-basis: 65%;
  text-align: left;

  > * {
    max-width: 600px;
  }
`;

export const SubscribeAreaWrapper = styled.div`
  flex-basis: 25%;
  margin-block-end: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-block-end: 0;
  }
`;

const linkStyle = css`
  padding: 8px 4px;
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    flex-direction: row;
    align-items: center;
  }
`;

const footerLinkStyle = css`
  display: inline-block;
  font-size: 14px;
  line-height: ${lineHeights.s};
  color: ${colors.white};
  cursor: pointer;

  &:hover {
    color: ${colors.grayBtnHover};
  }

  @media screen and (hover: none) {
    min-height: 24px;
    min-width: 48px;
  }
`;

export const LinkList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  padding: 0;
  margin: 0;
`;
export const LinkListItem = styled.li`
  list-style: none;

  &:not(:last-child) {
    &::after {
      content: '|';
      margin-inline: 4px;
    }
  }
`;

export const FooterLink = styled(Link)`
  ${footerLinkStyle};
`;

export const CookieSettings = styled.button`
  appearance: none;
  border: none;
  border-radius: 0;
  background: transparent;
  ${footerLinkStyle};
  font-size: 14px;
  line-height: ${lineHeights.s};
  height: auto;
  display: inline-block;
  margin: 0;
  padding: 0;
  min-width: 48px;
`;

export const SocialMedia = styled.div`
  display: flex;
  padding-block-end: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-block-end: 0;
  }
`;

export const SocialMediaLink = styled(Link)`
  display: block;
  width: 40px;
  height: 40px;

  & + & {
    margin-inline-start: 24px;
  }
`;

export const Logo = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 25px;
  overflow: hidden;
  margin-left: -24px;
`;

export const FooterTop = styled.div`
  display: flex;
  margin-block-end: 48px;
  justify-content: space-between;

  @media screen and (max-width: ${breakpoints.desktop}) {
    margin-block-end: 40px;
    flex-direction: column;
  }
`;

export const FooterFunctions = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${breakpoints.desktop}) {
    margin-block-start: 40px;
  }
`;

export const PartnerLoginLink = styled(Link)`
  margin-inline-end: 12px;

  svg {
    margin-left: 6px;
  }
`;
