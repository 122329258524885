import { WorkwearGuideFilterSpec } from '@hultafors/snickers/types';

export const categoryList = [
  {
    name: 'Trousers',
    nodeId: 583,
    steps: 7,
  },
  {
    name: 'Jackets',
    nodeId: 584,
    steps: 7,
  },
  {
    name: 'Gloves',
    nodeId: 585,
    steps: 6,
  },
  {
    name: 'Protect work',
    nodeId: 676,
    steps: 7,
  },
];

export const categorySpec = {
  trousers: 583,
  jackets: 584,
  gloves: 585,
  protectWork: 676,
};

export const filterSpec: WorkwearGuideFilterSpec = {
  gender: { id: 416, name: 'gender' },
  indoorOutdoor: { id: 760, name: 'indoorOutdoor' },
  environment: { id: 762, name: 'environment' },
  riskEnvironment: { id: 765, name: 'riskEnvironment' },
  occupation: { id: 742, name: 'occupation' },
  features: { id: 418, name: 'features' },
  certification: { id: 453, name: 'certification' },
  fit: { id: 529, name: 'fit' },
  garment: { id: 611, name: 'garment' },
};
