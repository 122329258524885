import Link from 'next/link';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/snickers/helpers';

interface TransparentProps {
  $transparent?: boolean;
}

export const NavigationStyled = styled.div<TransparentProps>`
  width: 100%;
  text-align: center;
  background-color: ${({ $transparent }) =>
    $transparent ? 'transparent' : colors.white};
  transition: all 0.5s ease;

  @media screen and (min-width: ${breakpoints.desktop}) {
    border-bottom: 1px solid
      ${({ $transparent }) => ($transparent ? 'transparent' : colors.gray4)};
  }
`;

export const Logo = styled(Link)`
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 40px;
  width: 75px;
  background-color: ${colors.workwearBlack};

  @media screen and (min-width: ${breakpoints.desktop}) {
    position: relative;
    left: auto;
    transform: none;
  }
`;

export const MobileNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 56px;
  background-color: ${colors.white};
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid ${colors.gray4};

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: none;
  }

  span {
    align-self: center;
  }
`;

export const MobileNavItem = styled.span`
  align-self: center;
`;

export const MenuToggler = styled.button`
  appearance: none;
  cursor: pointer;
  width: 48px;
  height: 48px;
  background: none;
  border: none;
  padding: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -12px;
`;

export const MenuActions = styled.div`
  align-self: center;
  display: flex;
  flex-direction: row;

  > * {
    margin-left: ${spacing.tinier};
  }
`;

export const DesktopNav = styled.div`
  position: relative;
  display: none;
  height: 56px;
  margin: 0 auto;

  /* padding: 0 20px; */
  width: calc(100vw - 40px);
  max-width: ${breakpoints.maxPageWidth};
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: flex;
  }
`;

export const TopActions = styled.div<TransparentProps>`
  display: flex;

  > * + * {
    margin-left: 1rem;
  }
  ${({ $transparent }) =>
    $transparent
    && css`
      .Search svg path {
        fill: ${colors.white};
      }

      .Language svg path {
        fill: ${colors.white};
      }

      > div {
        .Favorite svg,
        .Cart svg {
          path,
          rect.stroke {
            stroke: ${colors.white};
          }

          rect.fill {
            fill: ${colors.white};
          }
        }

        svg g g {
          fill: ${colors.white};
        }
      }
    `}
`;

export const TopActionsLabel = styled.span<TransparentProps>`
  white-space: nowrap;
  font-size: 10px;
  color: ${({ $transparent }) =>
    $transparent ? colors.white : colors.workwearBlack};
  letter-spacing: 0.45px;
  margin-block-start: 0.25rem;
`;

export const Search = styled.button`
  appearance: none;
  background: transparent;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block-start: 2px;

  * {
    pointer-events: none;
  }
`;

export const ChangeCountry = styled.button`
  appearance: none;
  background: transparent;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block-start: 2px;

  * {
    pointer-events: none;
  }
`;

export const Partner = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding-block-start: 2px;
  cursor: pointer;
`;

export const TopMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > * + * {
    margin-left: ${spacing.small};
  }
`;

interface TopMenuItemProps extends TransparentProps {
  $active?: boolean;
}

const topMenuItemStyle = css<TopMenuItemProps>`
  position: relative;
  display: flex;
  font: ${fontFamilies.fontRegular};
  font-size: ${fontSizes.body};
  font-weight: ${fontWeights.medium};
  letter-spacing: 0.5px;
  color: ${colors.workwearBlack};
  text-decoration: none;
  transition: all 0.2s linear;
  text-transform: capitalize;
  align-items: center;
  justify-content: center;
  height: 48px;

  &:empty {
    display: none;
  }

  &:hover {
    color: ${colors.gray1};
  }

  &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 16px;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    background: ${colors.gray1};
    border-radius: 2px;
    visibility: hidden;
  }

  ${({ $active }) =>
    $active
    && css`
      ::after {
        visibility: visible;
      }
    `}
  &:hover::after {
    visibility: visible;
  }

  ${({ $transparent }) =>
    $transparent
    && css`
      color: ${colors.white};

      &::after {
        background: ${colors.white};
      }

      &:hover {
        color: ${colors.white};
      }
    `}
`;
export const TopMenuProducts = styled.button<TopMenuItemProps>`
  appearance: none;
  background: transparent;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;

  ${topMenuItemStyle}
`;

export const TopMenuItem = styled(Link)<TopMenuItemProps>`
  position: relative;
  display: inline-flex;

  ${topMenuItemStyle};
`;
