import styled from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/snickers/helpers';

import { HTag } from '../HTag/HTag';
import { RouterLink } from '../RouterLink/RouterLink';

export const StyledSmallContentPlug = styled(RouterLink)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  height: 100%;
`;
export const ImageContainer = styled.div`
  position: relative;
  aspect-ratio: 1;
`;
export const StyledImage = styled.img`
  width: 100%;
  height: auto;
`;

export const StyledNoImageDiv = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.gray5};
`;
export const StyledTextBoxWrapper = styled.div`
  padding-block-start: 16px;
  padding-inline-end: 24px;
`;
export const StyledTextBoxTitle = styled(HTag)`
  margin-block: 0 16px;
`;
export const StyledTextBoxText = styled.p`
  font-family: ${fontFamilies.faktPro};
  display: box;
  margin-block: 0 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: ${fontSizes.contentMobile};
  line-height: ${lineHeights.contentMobile};
  font-weight: ${fontWeights.normal};
  letter-spacing: 0.02rem;
`;
export const StyledTextBoxCTAWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: ${spacing.tinier};
  }
`;

export const StyledTextBoxCTA = styled.p`
  font-family: ${fontFamilies.faktPro};
  margin: 0;
  font-size: ${fontSizes.contentMobile};
  line-height: ${lineHeights.contentMobile};
  font-weight: ${fontWeights.normal};
  letter-spacing: 0.02rem;
`;
