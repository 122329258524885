import styled from 'styled-components';

import { colors, fontSizes, lineHeights } from '@hultafors/snickers/helpers';

export const StoreItemStyled = styled.div`
  a {
    text-decoration: none;
    font-size: 14px;

    &:hover {
      color: ${colors.solidBlackHover};
    }
  }

  p,
  label {
    font-size: 14px;
  }

  .Label {
    margin: 0;
  }

  .Address {
    margin: 0 0 30px;
  }

  .ViewOnMap {
    margin-block-end: 30px;
  }

  .LeftIconLink img,
  .LeftIconLink svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .Contact {
    display: flex;
    flex-direction: row;
    border-top: 1px solid ${colors.black};
    align-items: center;
    padding: 0;
    height: 60px;

    &.EmailAndPhoneWrapper {
      flex-direction: column;
      height: auto;
      padding: 20px 0;
      align-items: flex-start;
    }

    div {
      display: inline-flex;

      &.Email {
        padding: 0 0 20px;
      }
    }

    &.VisitSite {
      justify-content: flex-start;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;

        img,
        svg {
          width: 16px;
          height: 16px;
          margin-left: 8px;
        }
      }
    }

    label {
      width: 100%;
      margin-block-end: 6px;
    }

    &.StoreSells {
      align-items: center;
      min-height: 90px;
      height: auto;

      .StoresWrapper {
        flex-direction: column;

        .storeText {
          color: ${colors.gray1};
          font-size: ${fontSizes.body};
          line-height: ${lineHeights.body};
        }
      }

      a {
        text-decoration: underline;
      }
    }

    .Stores {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      div {
        .Separator {
          display: inline-flex;
          margin: 0 5px;
        }
      }
    }
  }

  .MapLink {
    text-decoration: none;
  }

  .LeftIconLink {
    position: relative;
    padding-left: 20px;
  }
`;
