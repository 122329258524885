import { Component } from 'react';

import Image from 'next/image';
import Slider from 'react-slick';

import { ImageFragment } from '@hultafors/snickers/types';

import { ImageSliderStyled, ImgWrapper } from './ImageSlider.styled';

export interface ImageSliderProps {
  images?: ImageFragment[];
}

interface ImageSliderState {
  currentIndex: number;
}

export class ImageSlider extends Component<ImageSliderProps, ImageSliderState> {
  slider: any;

  constructor(props: ImageSliderProps) {
    // super({ ...props, images: props.images || [] })
    super(props);

    this.state = {
      currentIndex: 1,
    };
  }

  setIndex = (index: number) => {
    this.setState({ currentIndex: index });
  };

  override componentWillUnmount() {
    if (this.slider) {
      this.slider.events.off('indexChanged', this.setIndex);
    }
  }

  override render() {
    const settings = {
      adaptiveHeight: false,
      infinite: true,
      speed: 500,
      lazyLoad: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            dots: false,
            arrows: true,
          },
        },
      ],
      afterChange: this.setIndex,
    };

    return (
      <ImageSliderStyled {...this.props}>
        <Slider {...settings}>
          {this.props.images?.map((item, index) => {
            return (
              <ImgWrapper key={`Carousel-${index}`}>
                <Image
                  src={item.responsiveImage?.src || ''}
                  alt={item.alt || ''}
                  fill
                  blurDataURL={item.responsiveImage?.base64 || ''}
                  placeholder={item.responsiveImage?.base64 ? 'blur' : 'empty'}
                />
              </ImgWrapper>
            );
          })}
        </Slider>
        <div className="Counter">
          {' '}
          <span className="ActiveSlide">
            {this.state.currentIndex + 1}
          </span>
          {' '}
          /
          {this.props.images?.length}
        </div>
      </ImageSliderStyled>
    );
  }
}

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  /* eslint-disable-next-line */
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  /* eslint-disable-next-line */
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}
