import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import styled, { css } from 'styled-components';

import { colors } from '@hultafors/snickers/helpers';

import { RouterLink } from '../RouterLink/RouterLink';

interface MenuProps {
  $menu?: boolean;
}

const AccordionLinkShared = css<MenuProps>`
  display: flex;
  border-top: thin solid ${colors.gray4};
  border-top: ${({ $menu }) => ($menu ? '0' : `thin solid ${colors.gray4}`)};
  width: 100%;
  padding: ${({ $menu }) => ($menu ? '8px' : '1.5rem')} 0;
  min-height: 48px;
  cursor: pointer;
  position: relative;
  margin: 0;
`;

export const AccordionLinkStyled = styled(RouterLink)<MenuProps>`
  ${AccordionLinkShared};
`;

export const AccordionItemStyled = styled(AccordionItem)`
  position: relative;
  width: 100%;
`;

export const AccordionItemHeadingStyled = styled(AccordionItemHeading)`
  width: 100%;
`;

export const ButtonInner = styled.span<MenuProps>`
  position: relative;
  ${AccordionLinkShared};
  min-height: 32px;
`;

export const AccordionItemButtonStyled = styled(AccordionItemButton)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;

  ${AccordionItemStyled} ${AccordionItemStyled} & {
    padding-inline-start: 20px;
  }
  ${AccordionItemStyled} ${AccordionItemStyled} ${AccordionItemStyled} & {
    padding-inline-start: 40px;
  }

  ::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: calc(50% - 8px);
    transition: all 0.1s ease-out;
    width: 16px;
    height: 16px;
    background-image: url('/svg/chevron.svg');
    background-repeat: no-repeat;
    background-size: 16px;
  }

  &[aria-expanded='true'] {
    ::after {
      content: '';
      transform: rotate(-180deg);
      transform-origin: 8px 8px;
    }
  }
`;

interface AccordionItemPanelStyledProps {
  $height?: number;
}

export const AccordionItemPanelStyled = styled(
  AccordionItemPanel,
)<AccordionItemPanelStyledProps>`
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;

  &[hidden] {
    display: block;
  }
`;

export const AccordionItemContent = styled.div`
  padding-block: 8px;
`;
