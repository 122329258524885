import { SizeItem, WaistList } from '@hultafors/snickers/types';

export const trousersMenWaists: WaistList = {
  body: [
    { inch: { min: 28, max: 29 }, cm: { min: 70, max: 73 } },
    { inch: { min: 30, max: 30 }, cm: { min: 74, max: 77 } },
    { inch: { min: 31, max: 32 }, cm: { min: 78, max: 81 } },
    { inch: { min: 33, max: 33 }, cm: { min: 82, max: 85 } },
    { inch: { min: 34, max: 35 }, cm: { min: 86, max: 89 } },
    { inch: { min: 36, max: 37 }, cm: { min: 90, max: 93 } },
    { inch: { min: 38, max: 38 }, cm: { min: 94, max: 97 } },
    { inch: { min: 39, max: 40 }, cm: { min: 98, max: 101 } },
    { inch: { min: 41, max: 41 }, cm: { min: 102, max: 105 } },
    { inch: { min: 42, max: 43 }, cm: { min: 106, max: 109 } },
    { inch: { min: 44, max: 46 }, cm: { min: 110, max: 117 } },
    { inch: { min: 47, max: 49 }, cm: { min: 118, max: 125 } },
    { inch: { min: 50, max: 52 }, cm: { min: 126, max: 133 } },
    { inch: { min: 53, max: 56 }, cm: { min: 134, max: 141 } },
    { inch: { min: 57, max: 59 }, cm: { min: 142, max: 149 } },
    { inch: { min: 60, max: 62 }, cm: { min: 150, max: 157 } },
    { inch: { min: 63, max: 65 }, cm: { min: 158, max: 165 } },
    { inch: { min: 66, max: 68 }, cm: { min: 166, max: 173 } },
    { inch: { min: 69, max: 71 }, cm: { min: 174, max: 181 } },
  ],
  garment: [
    { inch: { min: 27, max: 27 }, cm: { min: 67, max: 70 } },
    { inch: { min: 28, max: 29 }, cm: { min: 71, max: 74 } },
    { inch: { min: 30, max: 30 }, cm: { min: 75, max: 78 } },
    { inch: { min: 31, max: 32 }, cm: { min: 79, max: 82 } },
    { inch: { min: 33, max: 34 }, cm: { min: 83, max: 86 } },
    { inch: { min: 35, max: 35 }, cm: { min: 87, max: 90 } },
    { inch: { min: 36, max: 37 }, cm: { min: 91, max: 94 } },
    { inch: { min: 38, max: 38 }, cm: { min: 95, max: 98 } },
    { inch: { min: 39, max: 40 }, cm: { min: 99, max: 102 } },
    { inch: { min: 41, max: 42 }, cm: { min: 103, max: 106 } },
    { inch: { min: 43, max: 45 }, cm: { min: 107, max: 114 } },
    { inch: { min: 46, max: 48 }, cm: { min: 115, max: 122 } },
    { inch: { min: 49, max: 51 }, cm: { min: 123, max: 130 } },
    { inch: { min: 52, max: 54 }, cm: { min: 131, max: 138 } },
    { inch: { min: 55, max: 57 }, cm: { min: 139, max: 146 } },
    { inch: { min: 58, max: 60 }, cm: { min: 147, max: 154 } },
    { inch: { min: 61, max: 64 }, cm: { min: 155, max: 162 } },
    { inch: { min: 65, max: 67 }, cm: { min: 163, max: 170 } },
    { inch: { min: 68, max: 70 }, cm: { min: 171, max: 178 } },
  ],
};

export const trousersMen: SizeItem[] = [
  {
    id: 'trousers_m_1',
    name: 'Extra Short',
    leg: {
      garment: {
        cm: { min: 68, max: 73 },
        inch: { min: 27, max: 28 },
      },
      body: {
        cm: { min: 68, max: 73 },
        inch: { min: 27, max: 28 },
      },
    },

    sizes: [
      {
        size: 180,
      },
      {
        size: 184,
      },
      {
        size: 188,
      },
      {
        size: 192,
      },
      {
        size: 196,
      },
      {
        size: 200,
      },
      {
        size: 204,
      },
      {
        size: 208,
      },
      {
        size: 212,
      },
      {
        size: 216,
      },
      {
        size: 220,
      },
      {
        size: 224,
      },
      {
        size: 228,
      },
      {
        size: 232,
      },
      {
        size: 536,
      },
      {
        size: 540,
      },
      {
        size: 544,
      },
      {
        size: 548,
      },
      {
        size: 552,
      },
    ],
  },
  {
    id: 'trousers_m_2',
    name: 'Short',
    leg: {
      garment: {
        cm: { min: 74, max: 79 },
        inch: { min: 29, max: 31 },
      },
      body: {
        cm: { min: 74, max: 79 },
        inch: { min: 29, max: 31 },
      },
    },
    sizes: [
      {
        size: 80,
      },
      {
        size: 84,
      },
      {
        size: 88,
      },
      {
        size: 92,
      },
      {
        size: 96,
      },
      {
        size: 100,
      },
      {
        size: 104,
      },
      {
        size: 108,
      },
      {
        size: 112,
      },
      {
        size: 116,
      },
      {
        size: 120,
      },
      {
        size: 124,
      },
      {
        size: 128,
      },
      {
        size: 132,
      },
      {
        size: 636,
      },
      {
        size: 640,
      },
      {
        size: 644,
      },
      {
        size: 648,
      },
      {
        size: 652,
      },
    ],
  },
  {
    id: 'trousers_m_3',
    name: 'Regular',
    leg: {
      garment: {
        cm: { min: 80, max: 85 },
        inch: { min: 32, max: 33 },
      },
      body: {
        cm: { min: 80, max: 85 },
        inch: { min: 32, max: 33 },
      },
    },
    sizes: [
      {
        size: 40,
      },
      {
        size: 42,
      },
      {
        size: 44,
      },
      {
        size: 46,
      },
      {
        size: 48,
      },
      {
        size: 50,
      },
      {
        size: 52,
      },
      {
        size: 54,
      },
      {
        size: 56,
      },
      {
        size: 58,
      },
      {
        size: 60,
      },
      {
        size: 62,
      },
      {
        size: 64,
      },
      {
        size: 66,
      },
      {
        size: 68,
      },
      {
        size: 70,
      },
      {
        size: 772,
      },
      {
        size: 774,
      },
      {
        size: 776,
      },
    ],
  },
  {
    id: 'trousers_m_4',
    name: 'Long',
    leg: {
      garment: { cm: { min: 86, max: 91 }, inch: { min: 34, max: 35 } },
      body: { cm: { min: 86, max: 91 }, inch: { min: 34, max: 35 } },
    },
    sizes: [
      {
        size: 140,
      },
      {
        size: 142,
      },
      {
        size: 144,
      },
      {
        size: 146,
      },
      {
        size: 148,
      },
      {
        size: 150,
      },
      {
        size: 152,
      },
      {
        size: 154,
      },
      {
        size: 156,
      },
      {
        size: 158,
      },
      {
        size: 160,
      },
      {
        size: 162,
      },
      {
        size: 164,
      },
      {
        size: 166,
      },
      {
        size: 168,
      },
      {
        size: 170,
      },
      {
        size: 872,
      },
      {
        size: 874,
      },
      {
        size: 876,
      },
    ],
  },
  {
    id: 'trousers_m_5',
    name: 'Extra Long',
    leg: {
      garment: { cm: { min: 92, max: 97 }, inch: { min: 36, max: 38 } },
      body: {
        cm: { min: 92, max: 97 },
        inch: { min: 36, max: 38 },
      },
    },
    sizes: [
      {
        size: 240,
      },
      {
        size: 242,
      },
      {
        size: 244,
      },
      {
        size: 246,
      },
      {
        size: 248,
      },
      {
        size: 250,
      },
      {
        size: 252,
      },
      {
        size: 254,
      },
      {
        size: 256,
      },
      {
        size: 258,
      },
      {
        size: 260,
      },
      {
        size: 262,
      },
      {
        size: 264,
      },
      {
        size: 266,
      },
      {
        size: 268,
      },
      {
        size: 270,
      },
      {
        size: 272,
      },
      {
        size: 274,
      },
      {
        size: 276,
      },
    ],
  },
];
