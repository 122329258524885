import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Markdown } from '../Markdown/Markdown';

import {
  ButtonGridChild,
  Content,
  FirstContent,
  StyledHeader,
  StyledLinkButton,
} from './TwoColumnText.styled';

export interface TwoColumnTextProps {
  firstColumnText?: string;
  secondColumnText?: string;
  header?: string;
  CTAText?: string;
  CTALink?: string;
}

export const TwoColumnText: React.FC<TwoColumnTextProps> = ({
  firstColumnText,
  secondColumnText,
  header,
  CTALink,
  CTAText,
}) => {
  return (
    <Grid
      columnGap={[
        { columnGap: 15 },
        { breakpoint: 'mobileMax', columnGap: 30 },
      ]}
      columns={[{ columns: 1 }, { breakpoint: 'mobileMax', columns: 12 }]}
    >
      <GridChild>{!!header && <StyledHeader>{header}</StyledHeader>}</GridChild>
      <GridChild
        columnSpan={[{ breakpoint: 'mobileMax', start: 2, columns: 5 }]}
      >
        {!!firstColumnText && (
          <FirstContent>
            <Markdown>{firstColumnText}</Markdown>
          </FirstContent>
        )}
      </GridChild>
      <GridChild
        columnSpan={[{ breakpoint: 'mobileMax', start: 7, columns: 5 }]}
      >
        {!!secondColumnText && (
          <Content>
            <Markdown>{secondColumnText}</Markdown>
          </Content>
        )}
      </GridChild>
      <ButtonGridChild>
        {!!CTAText && !!CTALink && (
          <StyledLinkButton text={CTAText} url={CTALink} className="Grey" />
        )}
      </ButtonGridChild>
    </Grid>
  );
};
