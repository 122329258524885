import styled from 'styled-components';

import { colors } from '@hultafors/snickers/helpers';

export const CartStyled = styled.div`
  .SlideInBody {
    position: relative;
    padding-block-end: 150px;
  }

  .SendListWrapper {
    position: fixed;
    box-sizing: border-box;
    width: 100%;
    bottom: 0;
    margin: 0 -20px;
    padding: 20px;
    background-color: ${colors.white};
    box-shadow: 0 0 3px 0 ${colors.boxshadowTransparent};

    h4 {
      margin-block-start: 0;
    }
  }
`;
