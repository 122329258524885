import localFont from '@next/font/local';

const fontFaktPro = localFont({
  src: [
    {
      path: './Fakt-Normal.woff2',
      weight: '300',
      style: 'normal',
    },
    {
      path: './Fakt-Medium.woff2',
      weight: '400',
      style: 'bold',
    },
  ],
  variable: '--font-fakt-pro',
});

const fontMikro = localFont({
  src: [
    {
      path: './Mikro-Medium.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: './Mikro-Bold.woff2',
      weight: '700',
      style: 'bold',
    },
  ],
  variable: '--font-mikro',
});

const fonts = [fontFaktPro.variable, fontMikro.variable].join(' ');

interface FontsProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export const Fonts: React.FC<FontsProps> = ({ children, style }) => {
  return (
    <div className={fonts} style={style}>
      <style jsx global>
        {`
        html,
        input,
        textarea,
        button,
        select {
          font-family: ${fontFaktPro.style.fontFamily};
        }
      `}
      </style>
      {children}
    </div>
  );
};
