import { Component } from 'react';

import Image from 'next/image';
import Slider from 'react-slick';

import { ImageSliderFragment } from '@hultafors/snickers/types';

import { Grid } from '../../../Grid/Grid';
import { GridChild } from '../../../GridChild/GridChild';

import { ImageSliderStyled, ImgWrapper } from './ImageSliderBlock.styled';

interface ImageSliderState {
  currentIndex: number;
}

export class ImageSliderBlock extends Component<
  ImageSliderFragment,
  ImageSliderState
> {
  slider: any;

  constructor(props: ImageSliderFragment) {
    super(props);

    this.state = {
      currentIndex: 1,
    };
  }

  setIndex = (index: number) => {
    this.setState({ currentIndex: index });
  };

  override componentWillUnmount() {
    if (this.slider) {
      this.slider.events.off('indexChanged', this.setIndex);
    }
  }

  override render() {
    const settings = {
      adaptiveHeight: false,
      infinite: true,
      speed: 500,
      lazyLoad: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            dots: false,
            arrows: true,
          },
        },
      ],
      afterChange: this.setIndex,
    };

    return (
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 12 },
            { breakpoint: 'desktop', columns: 10, start: 2 },
          ]}
        >
          <ImageSliderStyled {...this.props}>
            <Slider {...settings}>
              {this.props.imageSlider?.map((item, index) => {
                return (
                  <ImgWrapper key={`Carousel-${index}`}>
                    <Image
                      src={item.responsiveImage?.src || ''}
                      alt={item.alt || ''}
                      fill
                      blurDataURL={item.responsiveImage?.base64 || ''}
                      placeholder={
                        item.responsiveImage?.base64 ? 'blur' : 'empty'
                      }
                    />
                  </ImgWrapper>
                );
              })}
            </Slider>
            <div className="Counter">
              {' '}
              <span className="ActiveSlide">
                {this.state.currentIndex + 1}
              </span>
              {' '}
              /
              {' '}
              {this.props.imageSlider?.length}
            </div>
          </ImageSliderStyled>
        </GridChild>
      </Grid>
    );
  }
}

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  /* eslint-disable-next-line */
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  /* eslint-disable-next-line */
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}
