import Link from 'next/link';
import styled from 'styled-components';

import { colors, fontWeights } from '@hultafors/snickers/helpers';

export const ReadMoreLinkStyled = styled(Link)`
  border-top: thin solid ${colors.gray4};
  text-decoration: none;
  margin: 0;
  line-height: 56px;
  font-size: 14px;
  display: flex;
  align-items: center;
  width: auto;
  font-weight: ${fontWeights.normal};
  margin-block-start: 12px;

  svg {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
    transform: rotate(-90deg);

    path {
      fill: ${colors.workwearBlack};
      transition: all 0.2s;
    }
  }

  &:hover {
    color: ${colors.gray1};

    svg path {
      fill: ${colors.gray1};
    }
  }
`;
