import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/snickers/helpers';

export const MobileFilterBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0 15px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: none;
  }
`;

export const MobileFilterButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
`;

export const FilterWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: flex;
    flex-direction: column;

    .SelectedFilters {
      position: relative;
      padding: 20px 10px;
      margin-block-end: 24px;
      background-color: ${colors.gray5};

      h4 {
        margin: 0 0 16px;
      }

      button {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        width: auto;
        height: 30px;
        margin-block-start: 12px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 0;
        background: none;
        font-size: 14px;

        img {
          margin-left: 8px;
        }
      }

      span {
        font-size: 14px;
        line-height: 24px;
        color: ${colors.workwearBlack};

        &:not(:last-of-type)::after {
          content: ', ';
        }
      }
    }
  }
`;
