import Image from 'next/image';

import { ImageFragment } from '@hultafors/snickers/types';

import {
  CertificatePlugStyled,
  Description,
  Title,
} from './CertificatePlug.styled';

export interface CertificatePlugProps {
  title?: string | null;
  url?: string | null;
  ingress?: string | null;
  image?: ImageFragment | null;
}

export const CertificatePlug: React.FC<CertificatePlugProps> = ({
  url,
  title,
  ingress,
  image,
}) => {
  let placeholder: 'empty' | 'blur' = 'empty';
  if (image?.responsiveImage?.base64) {
    placeholder = 'blur';
  }
  return (
    <CertificatePlugStyled href={url ?? ''}>
      {image?.url && (
        <Image
          src={image.url}
          alt={title ?? image.alt ?? ''}
          width={48}
          height={48}
          style={{ objectFit: 'contain', marginInline: 'auto' }}
          blurDataURL={image.responsiveImage?.base64 ?? ''}
          placeholder={placeholder}
        />
      )}
      <Title>{title}</Title>
      <Description>{ingress}</Description>
    </CertificatePlugStyled>
  );
};
