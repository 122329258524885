import { JeansSize } from '@hultafors/snickers/types';

export const jeansSizesMen: JeansSize[] = [
  {
    leg: 28,
    waist: [
      {
        value: 30,
        size: 188,
      },
      {
        value: 31,
        size: 192,
      },
      {
        value: 32,
        size: 192,
      },
      {
        value: 33,
        size: 196,
      },
      {
        value: 34,
        size: 196,
      },
      {
        value: 35,
        size: 200,
      },
      {
        value: 36,
        size: 204,
      },
      {
        value: 37,
        size: 204,
      },
      {
        value: 38,
        size: 208,
      },
      {
        value: 39,
        size: 212,
      },
      {
        value: 40,
        size: 212,
      },
      {
        value: 41,
        size: 216,
      },
      {
        value: 42,
        size: 216,
      },
      {
        value: 43,
        size: 216,
      },
      {
        value: 44,
        size: 220,
      },
      {
        value: 45,
        size: 220,
      },
      {
        value: 46,
        size: 220,
      },
      {
        value: 47,
        size: 224,
      },
      {
        value: 48,
        size: 224,
      },
      {
        value: 49,
        size: 224,
      },
      {
        value: 50,
        size: 228,
      },
      {
        value: 51,
        size: 228,
      },
      {
        value: 52,
        size: 228,
      },
      {
        value: 53,
        size: 228,
      },
      {
        value: 54,
        size: 232,
      },
      {
        value: 55,
        size: 232,
      },
      {
        value: 56,
        size: 232,
      },
      {
        value: 57,
        size: 536,
      },
      {
        value: 58,
        size: 540,
      },
      {
        value: 59,
        size: 540,
      },
      {
        value: 60,
        size: 540,
      },
    ],
  },
  {
    leg: 30,
    waist: [
      {
        value: 30,
        size: 88,
      },
      {
        value: 31,
        size: 92,
      },
      {
        value: 32,
        size: 92,
      },
      {
        value: 33,
        size: 96,
      },
      {
        value: 34,
        size: 96,
      },
      {
        value: 35,
        size: 100,
      },
      {
        value: 36,
        size: 104,
      },
      {
        value: 37,
        size: 104,
      },
      {
        value: 38,
        size: 108,
      },
      {
        value: 39,
        size: 112,
      },
      {
        value: 40,
        size: 112,
      },
      {
        value: 41,
        size: 116,
      },
      {
        value: 42,
        size: 116,
      },
      {
        value: 43,
        size: 116,
      },
      {
        value: 44,
        size: 120,
      },
      {
        value: 45,
        size: 120,
      },
      {
        value: 46,
        size: 120,
      },
      {
        value: 47,
        size: 124,
      },
      {
        value: 48,
        size: 124,
      },
      {
        value: 49,
        size: 124,
      },
      {
        value: 50,
        size: 128,
      },
      {
        value: 51,
        size: 128,
      },
      {
        value: 52,
        size: 128,
      },
      {
        value: 53,
        size: 128,
      },
      {
        value: 54,
        size: 132,
      },
      {
        value: 55,
        size: 132,
      },
      {
        value: 56,
        size: 132,
      },
      {
        value: 57,
        size: 636,
      },
      {
        value: 58,
        size: 640,
      },
      {
        value: 59,
        size: 640,
      },
      {
        value: 60,
        size: 640,
      },
    ],
  },
  {
    leg: 32,
    waist: [
      {
        value: 30,
        size: 44,
      },
      {
        value: 31,
        size: 46,
      },
      {
        value: 32,
        size: 48,
      },
      {
        value: 33,
        size: 48,
      },
      {
        value: 34,
        size: 50,
      },
      {
        value: 35,
        size: 50,
      },
      {
        value: 36,
        size: 52,
      },
      {
        value: 37,
        size: 54,
      },
      {
        value: 38,
        size: 54,
      },
      {
        value: 39,
        size: 56,
      },
      {
        value: 40,
        size: 58,
      },
      {
        value: 41,
        size: 58,
      },
      {
        value: 42,
        size: 60,
      },
      {
        value: 43,
        size: 60,
      },
      {
        value: 44,
        size: 60,
      },
      {
        value: 45,
        size: 62,
      },
      {
        value: 46,
        size: 62,
      },
      {
        value: 47,
        size: 62,
      },
      {
        value: 48,
        size: 64,
      },
      {
        value: 49,
        size: 64,
      },
      {
        value: 50,
        size: 64,
      },
      {
        value: 51,
        size: 66,
      },
      {
        value: 52,
        size: 66,
      },
      {
        value: 53,
        size: 66,
      },
      {
        value: 54,
        size: 66,
      },
      {
        value: 55,
        size: 68,
      },
      {
        value: 56,
        size: 68,
      },
      {
        value: 57,
        size: 68,
      },
      {
        value: 58,
        size: 70,
      },
      {
        value: 59,
        size: 70,
      },
      {
        value: 60,
        size: 70,
      },
    ],
  },
  {
    leg: 35,
    waist: [
      {
        value: 30,
        size: 144,
      },
      {
        value: 31,
        size: 146,
      },
      {
        value: 32,
        size: 148,
      },
      {
        value: 33,
        size: 148,
      },
      {
        value: 34,
        size: 150,
      },
      {
        value: 35,
        size: 150,
      },
      {
        value: 36,
        size: 152,
      },
      {
        value: 37,
        size: 154,
      },
      {
        value: 38,
        size: 154,
      },
      {
        value: 39,
        size: 156,
      },
      {
        value: 40,
        size: 158,
      },
      {
        value: 41,
        size: 158,
      },
      {
        value: 42,
        size: 160,
      },
      {
        value: 43,
        size: 160,
      },
      {
        value: 44,
        size: 160,
      },
      {
        value: 45,
        size: 162,
      },
      {
        value: 46,
        size: 162,
      },
      {
        value: 47,
        size: 162,
      },
      {
        value: 48,
        size: 164,
      },
      {
        value: 49,
        size: 164,
      },
      {
        value: 50,
        size: 164,
      },
      {
        value: 51,
        size: 166,
      },
      {
        value: 52,
        size: 166,
      },
      {
        value: 53,
        size: 166,
      },
      {
        value: 54,
        size: 166,
      },
      {
        value: 55,
        size: 168,
      },
      {
        value: 56,
        size: 168,
      },
      {
        value: 57,
        size: 168,
      },
      {
        value: 58,
        size: 170,
      },
      {
        value: 59,
        size: 170,
      },
      {
        value: 60,
        size: 170,
      },
    ],
  },
  {
    leg: 37,
    waist: [
      {
        value: 30,
        size: 244,
      },
      {
        value: 31,
        size: 246,
      },
      {
        value: 32,
        size: 248,
      },
      {
        value: 33,
        size: 248,
      },
      {
        value: 34,
        size: 250,
      },
      {
        value: 35,
        size: 250,
      },
      {
        value: 36,
        size: 252,
      },
      {
        value: 37,
        size: 254,
      },
      {
        value: 38,
        size: 254,
      },
      {
        value: 39,
        size: 256,
      },
      {
        value: 40,
        size: 258,
      },
      {
        value: 41,
        size: 258,
      },
      {
        value: 42,
        size: 260,
      },
      {
        value: 43,
        size: 260,
      },
      {
        value: 44,
        size: 260,
      },
      {
        value: 45,
        size: 262,
      },
      {
        value: 46,
        size: 262,
      },
      {
        value: 47,
        size: 262,
      },
      {
        value: 48,
        size: 264,
      },
      {
        value: 49,
        size: 264,
      },
      {
        value: 50,
        size: 264,
      },
      {
        value: 51,
        size: 266,
      },
      {
        value: 52,
        size: 266,
      },
      {
        value: 53,
        size: 266,
      },
      {
        value: 54,
        size: 266,
      },
      {
        value: 55,
        size: 268,
      },
      {
        value: 56,
        size: 268,
      },
      {
        value: 57,
        size: 268,
      },
      {
        value: 58,
        size: 270,
      },
      {
        value: 59,
        size: 270,
      },
      {
        value: 60,
        size: 270,
      },
    ],
  },
];
