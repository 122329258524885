import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

interface StyledOptionsBarButtonProps {
  $chevron?: boolean;
  $sizeGuide?: boolean;
  $color?: boolean;
}

export const StyledOptionsBarButton = styled.button<StyledOptionsBarButtonProps>`
  display: flex;
  height: 56px;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  background: none;
  border: none;

  &::after {
    content: '';
    display: inline-flex;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 10px;
    ${({ $chevron }) =>
      $chevron
      && css`
        background-image: url('/svg/next.svg');
        width: 12px;
        height: 12px;
      `}
    ${({ $sizeGuide }) =>
      $sizeGuide
      && css`
        background-image: url('/svg/measure.svg');
        width: 16px;
        height: 16px;
      `}
    ${({ $color }) =>
      $color
      && css`
        background-image: url('/svg/next.svg');
        width: 12px;
        height: 12px;

        @media screen and (max-width: ${breakpoints.desktopSmall}) {
          display: none;
        }
      `}
  }
`;
