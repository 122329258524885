import styled from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/snickers/helpers';

interface SizeSelectorStyledProps {
  $desktopMenu?: boolean;
}

export const ContentWrapper = styled.div``;

export const TextAndSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin: 0;
  }
`;

export const SelectWrapper = styled.div`
  position: relative;

  select {
    appearance: none;
    border: none;
    background: none;
    padding: 10px 20px 10px 10px;
    direction: rtl;
    font-size: 14px;
    cursor: pointer;
  }

  &::after {
    content: '';
    height: 12px;
    width: 12px;
    background-image: url('/assets/gfx/chevron.svg');
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const HeaderButton = styled.button<SizeSelectorStyledProps>`
  display: flex;
  flex-direction: column;
  flex: ${({ $desktopMenu }) => ($desktopMenu ? '0 0 170px' : '0 1 auto')};
  position: relative;
  height: 28px;
  cursor: pointer;
  color: ${colors.gray2};
  white-space: nowrap;
  margin-inline-end: ${({ $desktopMenu }) => ($desktopMenu ? '0' : '30px')};
  scroll-snap-align: center;

  &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 16px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: ${colors.workwearBlack};
    display: none;
  }

  &:hover,
  &.Selected {
    color: ${colors.workwearBlack};
  }

  &.Selected::after {
    display: block;
  }
`;

export const SizeSelectorHeader = styled.header`
  justify-content: flex-end;
  display: flex;
  margin-inline: 20px;
  margin-top: ${spacing.tinier};
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  overflow-x: auto;
  scrollbar-width: none;

  a:last-child {
    padding-inline-end: 16px;
  }
`;

export const Sizes = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${spacing.tinier};
  margin-block-start: ${spacing.tinier};
  margin-inline: 20px;
`;
interface SizeButtonProps {
  $selected?: boolean;
  $wide?: boolean;
}
export const SizeButton = styled.button<SizeButtonProps>`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  border-radius: 2px;
  cursor: pointer;
  font-size: 12px;
  padding: 0;
  background-color: ${({ $selected }) =>
    $selected ? colors.workwearBlack : colors.gray5};
  color: ${({ $selected }) => ($selected ? colors.white : 'inherit')};

  &:hover {
    background-color: ${colors.gray3};
  }
`;

export const NotStandardSize = styled.p`
  font-family: ${fontFamilies.faktPro};
  line-height: ${lineHeights.bodyMobile};
  text-align: center;
  font-size: ${fontSizes.m};
  margin-block-start: 0;
`;

export const SizeSelectorStyled = styled.section``;
