import { createGlobalStyle, css } from 'styled-components';

import { colors, fontWeights } from '@hultafors/snickers/helpers';
export const HEADER_HEIGHT = '56px' as const;
export const OPTIONS_HEIGHT = '59px' as const;

const cssVariables = css`
  :root {
    --header-height: ${HEADER_HEIGHT};
    --options-height: ${OPTIONS_HEIGHT};
    --map-offset: 0;
    --map-width: 100%;
    --map-height: 500px;
    --map-height-desktop: 600px;
  }
`;

const globalStyle = css`
  html {
    font-size: 14px;
    min-height: 100vh;
  }

  body {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-weight: ${fontWeights.normal};
    color: ${colors.workwearBlack};
    letter-spacing: 0.2px;
    background-color: ${colors.white};
    overflow-x: hidden;
  }

  p,
  div,
  span,
  form,
  input,
  textarea,
  button,
  select,
  article,
  section {
    font-size: 16px;
    font-weight: 300;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  #root {
    min-height: 100vh;
  }

  .map {
    position: relative;
    width: 100%;
  }

  .IE-Modal-Blur {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    background-color: ${colors.black};
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .IE-Modal-Contents {
    width: 90%;
    max-width: 500px;
    text-align: center;
    color: ${colors.black};
    background-color: ${colors.white};
    padding: 20px;
    margin: 0 auto;
    transform: translateY(50%);
  }

  .IE-Modal-Contents img {
    width: 60px;
    margin-block-end: 16px;
  }

  #__next {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${cssVariables};
  ${globalStyle};
`;
