import {
  JeansSizeLists,
  SizeConversion,
  SizeSelection,
  SizeValidation,
} from '@hultafors/snickers/types';

export const SIZE_GUIDE_STEPS = {
  INTRO: 0,
  MEASURE_BODY: 1,
  MEASURE_TROUSERS: 2,
  MEASURE_JEANS: 3,
  OTHER_BRAND: 4,
  RESULT: 5,
} as const;

export const DEFAULT_SELECTION: SizeSelection = {
  legSize: 0,
  waistSize: 0,
  unit: 'cm',
  otherBrand: {
    brandIndex: -1,
    legIndex: -1,
    sizeIndex: -1,
    size: '',
  },
} as const;

export const DEFAULT_RECOMMENDATION: SizeConversion = {
  size: 0, // trousers
  size3: 0, // trousers 3 series
  women: 0, // trousers
  pirates: 0,
  shorts: 0,
  shorts3: 0,
  current: 0, // Highlights current product if guide is started from detail view
} as const;

export const DEFAULT_VALIDATION: SizeValidation = {
  waistError: false,
  legsError: false,
  error: false,
} as const;

export const DEFAULT_SIZE_LISTS: JeansSizeLists = {
  list: [],
  men: [],
  men3: [],
  women: [],
};
