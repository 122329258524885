import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/snickers/helpers';

export const ProductInfoMobileStyled = styled.div`
  display: block;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    display: none;
  }

  .IconsAndPriceWrapper {
    position: relative;
    margin-block-end: 32px;
  }

  .Badge {
    position: absolute;
    right: 0;
    top: 3px;
  }

  .ProductCertificates {
    img {
      height: 24px;
      margin-inline-end: 16px;
    }
  }

  .ProductIntro {
    font-weight: ${fontWeights.medium};
    margin-block-end: ${spacing.regular};
  }

  .ProductDetailList {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: ${fontSizes.body};
    line-height: 24px;

    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      font-size: ${fontSizes.m};
    }

    li {
      display: inline-flex;
      padding: 0;
      margin-block-end: ${spacing.xSmall};

      &::before {
        position: relative;
        content: '';
        display: flex;
        flex-shrink: 0;
        width: 16px;
        height: 1px;
        background: ${colors.workwearBlack};
        margin-inline-end: 8px;
        top: 11px;
      }
    }
  }

  .ProductColorsContainer {
    padding: 24px;
    border-top: 1px solid ${colors.gray4};
  }
`;
