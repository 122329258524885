import styled, { CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontWeights, spacing } from '@hultafors/snickers/helpers';

interface PersonStyledProps {
  $marginTopMobile?: CSSProperties['marginTop'];
}

export const PersonBlockStyled = styled.article<PersonStyledProps>`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-column-gap: 20px;
  align-items: stretch;
  grid-auto-flow: row;
  max-width: 1440px;
  margin-inline: auto;
  margin-top: 0;
  padding-inline: 20px;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  @media screen and (min-width: ${breakpoints.maxPageWidth}) {
    width: calc(auto - 20px);
    padding-inline: 0;
  }
`;

export const Inner = styled.div<PersonStyledProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-column: span 4;
  justify-self: stretch;
  padding: 16px 0;
  border-top: 1px solid ${colors.gray4};
  border-bottom: 1px solid ${colors.gray4};
  margin-block: ${spacing.regular};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    grid-column: 2 / span 10;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-column: 3 / span 8;
  }

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    grid-column: 4 / span 6;
  }
`;

export const TextWrapper = styled.div`
  margin-left: 25px;

  p {
    font-size: 14px;
    font-weight: ${fontWeights.medium};
    margin: 0;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 64px;
  height: 64px;

  img {
    border-radius: 50%;
  }
`;

export const Label = styled.p`
  color: ${colors.gray2};
  margin-block-end: 8px;
`;

export const Name = styled.p`
  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 1px;
    width: 16px;
    background-color: ${colors.workwearBlack};
    margin-inline-end: 8px;
  }
`;
