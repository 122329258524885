import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/snickers/helpers';

export const DocumentLinkBlock = styled.div`
  margin: 12px 20px 32px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: 32px 0 16px;
  }

  a {
    display: flex;
    align-items: center;
    height: 64px;
    border-top: 1px solid ${colors.gray4};
    font-size: 14px;
    letter-spacing: 0.5px;
    text-decoration: none;

    &:hover {
      color: ${colors.gray1};
    }

    &:last-of-type {
      border-bottom: 1px solid ${colors.gray4};
    }

    img,
    svg {
      margin-inline-end: 12px;
    }
  }
`;
