export const spacing = {
  huge: '6.25em', // 100px
  large: '4.375em', // 70px
  medium: '3.125em', // 50px
  xMedium: '2em', // 32px
  small: '1.5em', // 24px
  xSmall: '1em', // 16px
  regular: '1.5rem', // 24px
  tiny: '0.75em', // 12px
  tinier: '0.5em', // 8px
  xxSmall: '0.25em', // 4px
  margin: '1.25em', // 20cpx
} as const;

export type Spacing = keyof typeof spacing;
