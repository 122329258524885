import { startTransition, useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';
import { KeyLoader } from 'swr';
import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite';

import { ParsedProductsApiResponse } from '@hultafors/shared/types';

import { SEARCH_PAGE_SIZE } from '@hultafors/snickers/api';
import { useGlobal } from '@hultafors/snickers/hooks';
import {
  CategoryGenericFragment,
  SnickersProduct,
} from '@hultafors/snickers/types';

import { Grid } from '../Grid/Grid';
import { Loader } from '../Loader/Loader';
import { Pager } from '../Pager/Pager';
import { Products } from '../Products/Products';
import { SearchInput } from '../SearchInput/SearchInput';
import { SearchResultHero } from '../SearchResultHero/SearchResultHero';
import { Section } from '../Section/Section';

interface SearchPageContentProps {
  categoryGeneric: CategoryGenericFragment;
  searchListApiBaseUrl: string;
  fallbackData: ParsedProductsApiResponse<SnickersProduct>[];
  initialTerm?: string;
}
export const SearchPageContent: React.FC<SearchPageContentProps> = ({
  categoryGeneric,
  searchListApiBaseUrl,
  fallbackData = [],
  initialTerm = '',
}) => {
  const { global } = useGlobal();
  const [term, setTerm] = useState(initialTerm);
  const [searchValue, setSearchValue] = useState(term);
  const { locale, ...router } = useRouter();

  // Creates api path that also acts as cache key for swr
  const getKey: KeyLoader = (
    index: number,
    previousPageData: ParsedProductsApiResponse<SnickersProduct> | null,
  ) => {
    // TODO Optimize when revalidation happens
    // if (searchValue === router.query['q']) {
    //   return null;
    // }
    if (
      previousPageData
      && previousPageData.paging.pageCount === previousPageData.paging.pageNumber
    ) {
      return null;
    }

    const params = new URLSearchParams({
      searchValue,
      pageSize: `${SEARCH_PAGE_SIZE}`,
      includeFilter: 'false',
      pageNumber: `${index + 1}`,
      ...(locale && locale !== 'com' ? { locale } : {}),
    });

    return `${searchListApiBaseUrl}?${params.toString()}`;
  };

  // Config swr to use server side data
  const swrConfig: SWRInfiniteConfiguration<
    ParsedProductsApiResponse<SnickersProduct>
  > = {
    fallbackData,
    initialSize: fallbackData?.length || 0,
  };

  // Initialize swr for search results fetching
  const { data, isValidating, size, setSize } = useSWRInfinite<
    ParsedProductsApiResponse<SnickersProduct>
  >(getKey, swrConfig);

  const products = useMemo(() => {
    return data?.flatMap(({ items }) => items) || [];
  }, [data]);

  const pagerData = useMemo(() => {
    const length = data?.length || 0;
    return data?.[length - 1]?.paging;
  }, [data]);

  const loading = useMemo<boolean>(() => {
    return isValidating && !!searchValue && searchValue !== router.query['q'];
  }, [isValidating, searchValue, router.query]);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    startTransition(() => {
      setTerm(event.target.value);
    });
  };

  const loadMore = () => {
    const newSize = size + 1;
    startTransition(() => {
      setSize(newSize);
    });
    const params = new URLSearchParams(router.query as Record<string, string>);
    params.delete('category');
    if (!newSize || newSize === 1) {
      params.delete('page');
    } else {
      params.set('page', `${newSize}`);
    }
    const query = params.toString();
    const path = router.asPath.split('?')[0];
    router.push(`${path}${query && `?${query}`}`, undefined, {
      shallow: true,
    });
  };

  const onSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    startTransition(() => {
      setSearchValue(term);
    });
  };

  const clear = () => {
    startTransition(() => {
      setTerm('');
      // setSearchValue('');
    });
  };

  useEffect(() => {
    if (searchValue) {
      const params = new URLSearchParams(
        router.query as Record<string, string>,
      );
      if (params.get('q') !== searchValue) {
        params.set('q', searchValue);
        params.delete('page');
        const query = params.toString();
        const path = router.asPath.split('?')[0];
        router.push(`${path}${query && `?${query}`}`, undefined, {
          shallow: true,
        });
        startTransition(() => {
          setSize(1);
        });
      }
    }
  }, [searchValue]);

  const message = useMemo<string>(() => {
    if (searchValue && !loading && data?.length) {
      const length = data?.length || 0;
      return (
        global?.searchResultCount
          ?.replace('$1', `${data?.[length - 1]?.paging?.itemCount}`)
          .replace('$2', searchValue) || ''
      );
    } else if (
      searchValue
      && !loading
      && !data?.flatMap(({ items }) => items)?.length
    ) {
      return global?.noSearchResults || '';
    }
    return '';
  }, [searchValue, loading, data, global]);
  return (
    <>
      <SearchResultHero message={message}>
        <SearchInput
          value={term || ''}
          onSubmit={onSubmit}
          onChange={onChange}
          onClear={clear}
          placeholder={global?.searchBoxPlaceholder || ''}
        />
        {loading && <Loader />}
      </SearchResultHero>
      <Section>
        {!loading && data?.length && (
          <>
            <Grid
              columnGap={[
                { columnGap: 15 },
                { breakpoint: 'desktop', columnGap: 30 },
              ]}
              columns={[
                { columns: 1 },
                { breakpoint: 'mediumMobile', columns: 2 },
                { breakpoint: 'mobileMax', columns: 3 },
                { breakpoint: 'desktop', columns: 4 },
              ]}
            >
              <Products
                products={products}
                badgeText={global?.new || ''}
                rrpLabel={global.rrpLabel || ''}
                rrpIncludingVatLabel={global.rrpIncludingVatLabel || ''}
              />
            </Grid>

            <Pager
              buttonText={categoryGeneric.loadMoreButton || ''}
              ofText={categoryGeneric.pagerOf || ''}
              productsText={categoryGeneric.pagerProductsLabel || ''}
              onClick={loadMore}
              pagerData={pagerData}
              loading={isValidating}
            />
          </>
        )}
      </Section>
    </>
  );
};
