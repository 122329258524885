import { SizeConversion } from '@hultafors/snickers/types';

export const sizeConversions: SizeConversion[] = [
  {
    size: 180,
    size3: 172,
    women: 16,
    shorts: 40,
    shorts3: 38,
    pirates: 80,
  },
  {
    size: 184,
    size3: 176,
    women: 17,
    shorts: 42,
    shorts3: 40,
    pirates: 84,
  },
  {
    size: 188,
    size3: 180,
    women: 18,
    shorts: 44,
    shorts3: 42,
    pirates: 88,
  },
  {
    size: 192,
    size3: 184,
    women: 19,
    shorts: 46,
    shorts3: 44,
    pirates: 92,
  },
  {
    size: 196,
    size3: 188,
    women: 20,
    shorts: 48,
    shorts3: 46,
    pirates: 96,
  },
  {
    size: 200,
    size3: 192,
    women: 21,
    shorts: 50,
    shorts3: 48,
    pirates: 100,
  },
  {
    size: 204,
    size3: 196,
    women: 22,
    shorts: 52,
    shorts3: 50,
    pirates: 104,
  },
  {
    size: 208,
    size3: 200,
    women: 23,
    shorts: 54,
    shorts3: 52,
    pirates: 108,
  },
  {
    size: 212,
    size3: 204,
    women: 24,
    shorts: 56,
    shorts3: 54,
    pirates: 112,
  },
  {
    size: 216,
    size3: 208,
    women: 25,
    shorts: 58,
    shorts3: 56,
    pirates: 116,
  },
  {
    size: 220,
    size3: 212,
    women: 26,
    shorts: 60,
    shorts3: 58,
    pirates: 120,
  },
  {
    size: 224,
    size3: 216,
    women: 27,
    shorts: 62,
    shorts3: 60,
    pirates: 124,
  },
  {
    size: 228,
    size3: 220,
    women: 628,
    shorts: 64,
    shorts3: 62,
    pirates: 128,
  },
  {
    size: 232,
    size3: 224,
    women: 629,
    shorts: 66,
    shorts3: 64,
    pirates: 132,
  },
  {
    size: 536,
    size3: 228,
    women: 630,
    shorts: 68,
    shorts3: 66,
    pirates: 636,
  },
  {
    size: 540,
    size3: 232,
    women: 631,
    shorts: 70,
    shorts3: 68,
    pirates: 640,
  },
  {
    size: 544,
    size3: 536,
    women: 632,
    shorts: 772,
    shorts3: 70,
    pirates: 644,
  },
  {
    size: 548,
    size3: 540,
    women: 633,
    shorts: 774,
    shorts3: 772,
    pirates: 648,
  },
  {
    size: 552,
    size3: 544,
    women: 0,
    shorts: 776,
    shorts3: 774,
    pirates: 652,
  },
  {
    size: 80,
    size3: 72,
    women: 32,
    shorts: 40,
    shorts3: 38,
    pirates: 80,
  },
  {
    size: 84,
    size3: 76,
    women: 34,
    shorts: 42,
    shorts3: 40,
    pirates: 84,
  },
  {
    size: 88,
    size3: 80,
    women: 36,
    shorts: 44,
    shorts3: 42,
    pirates: 88,
  },
  {
    size: 92,
    size3: 84,
    women: 38,
    shorts: 46,
    shorts3: 44,
    pirates: 92,
  },
  {
    size: 96,
    size3: 88,
    women: 40,
    shorts: 48,
    shorts3: 46,
    pirates: 96,
  },
  {
    size: 100,
    size3: 92,
    women: 42,
    shorts: 50,
    shorts3: 48,
    pirates: 100,
  },
  {
    size: 104,
    size3: 96,
    women: 44,
    shorts: 52,
    shorts3: 50,
    pirates: 104,
  },
  {
    size: 108,
    size3: 100,
    women: 46,
    shorts: 54,
    shorts3: 52,
    pirates: 108,
  },
  {
    size: 112,
    size3: 104,
    women: 48,
    shorts: 56,
    shorts3: 54,
    pirates: 112,
  },
  {
    size: 116,
    size3: 108,
    women: 50,
    shorts: 58,
    shorts3: 56,
    pirates: 116,
  },
  {
    size: 120,
    size3: 112,
    women: 52,
    shorts: 60,
    shorts3: 58,
    pirates: 120,
  },
  {
    size: 124,
    size3: 116,
    women: 54,
    shorts: 62,
    shorts3: 60,
    pirates: 124,
  },
  {
    size: 128,
    size3: 120,
    women: 756,
    shorts: 64,
    shorts3: 62,
    pirates: 128,
  },
  {
    size: 132,
    size3: 124,
    women: 758,
    shorts: 66,
    shorts3: 64,
    pirates: 132,
  },
  {
    size: 636,
    size3: 128,
    women: 760,
    shorts: 68,
    shorts3: 66,
    pirates: 636,
  },
  {
    size: 640,
    size3: 132,
    women: 762,
    shorts: 70,
    shorts3: 68,
    pirates: 640,
  },
  {
    size: 644,
    size3: 636,
    women: 764,
    shorts: 772,
    shorts3: 70,
    pirates: 644,
  },
  {
    size: 648,
    size3: 640,
    women: 766,
    shorts: 774,
    shorts3: 772,
    pirates: 648,
  },
  {
    size: 652,
    size3: 644,
    women: 0,
    shorts: 776,
    shorts3: 774,
    pirates: 652,
  },
  {
    size: 40,
    size3: 38,
    women: 64,
    shorts: 38,
    shorts3: 38,
    pirates: 40,
  },
  {
    size: 42,
    size3: 40,
    women: 68,
    shorts: 40,
    shorts3: 40,
    pirates: 42,
  },
  {
    size: 44,
    size3: 42,
    women: 72,
    shorts: 44,
    shorts3: 42,
    pirates: 44,
  },
  {
    size: 46,
    size3: 44,
    women: 76,
    shorts: 46,
    shorts3: 44,
    pirates: 46,
  },
  {
    size: 48,
    size3: 46,
    women: 80,
    shorts: 48,
    shorts3: 46,
    pirates: 48,
  },
  {
    size: 50,
    size3: 48,
    women: 84,
    shorts: 50,
    shorts3: 48,
    pirates: 50,
  },
  {
    size: 52,
    size3: 50,
    women: 88,
    shorts: 52,
    shorts3: 50,
    pirates: 52,
  },
  {
    size: 54,
    size3: 52,
    women: 92,
    shorts: 54,
    shorts3: 52,
    pirates: 54,
  },
  {
    size: 56,
    size3: 54,
    women: 96,
    shorts: 56,
    shorts3: 54,
    pirates: 56,
  },
  {
    size: 58,
    size3: 56,
    women: 100,
    shorts: 58,
    shorts3: 56,
    pirates: 58,
  },
  {
    size: 60,
    size3: 58,
    women: 104,
    shorts: 60,
    shorts3: 58,
    pirates: 60,
  },
  {
    size: 62,
    size3: 60,
    women: 108,
    shorts: 62,
    shorts3: 60,
    pirates: 62,
  },
  {
    size: 64,
    size3: 62,
    women: 112,
    shorts: 64,
    shorts3: 62,
    pirates: 64,
  },
  {
    size: 66,
    size3: 64,
    women: 816,
    shorts: 66,
    shorts3: 64,
    pirates: 66,
  },
  {
    size: 68,
    size3: 66,
    women: 820,
    shorts: 68,
    shorts3: 66,
    pirates: 68,
  },
  {
    size: 70,
    size3: 68,
    women: 824,
    shorts: 70,
    shorts3: 68,
    pirates: 70,
  },
  {
    size: 772,
    size3: 70,
    women: 828,
    shorts: 772,
    shorts3: 70,
    pirates: 772,
  },
  {
    size: 774,
    size3: 772,
    women: 832,
    shorts: 774,
    shorts3: 772,
    pirates: 774,
  },
  {
    size: 776,
    size3: 774,
    women: 0,
    shorts: 776,
    shorts3: 774,
    pirates: 776,
  },
  {
    size: 140,
    size3: 138,
    women: 164,
    shorts: 40,
    shorts3: 38,
    pirates: 40,
  },
  {
    size: 142,
    size3: 140,
    women: 168,
    shorts: 42,
    shorts3: 40,
    pirates: 42,
  },
  {
    size: 144,
    size3: 142,
    women: 172,
    shorts: 44,
    shorts3: 42,
    pirates: 44,
  },
  {
    size: 146,
    size3: 144,
    women: 176,
    shorts: 46,
    shorts3: 44,
    pirates: 46,
  },
  {
    size: 148,
    size3: 146,
    women: 180,
    shorts: 48,
    shorts3: 46,
    pirates: 48,
  },
  {
    size: 150,
    size3: 148,
    women: 184,
    shorts: 50,
    shorts3: 48,
    pirates: 50,
  },
  {
    size: 152,
    size3: 150,
    women: 188,
    shorts: 52,
    shorts3: 50,
    pirates: 52,
  },
  {
    size: 154,
    size3: 152,
    women: 192,
    shorts: 54,
    shorts3: 52,
    pirates: 54,
  },
  {
    size: 156,
    size3: 154,
    women: 196,
    shorts: 56,
    shorts3: 54,
    pirates: 56,
  },
  {
    size: 158,
    size3: 156,
    women: 200,
    shorts: 58,
    shorts3: 56,
    pirates: 58,
  },
  {
    size: 160,
    size3: 158,
    women: 204,
    shorts: 60,
    shorts3: 58,
    pirates: 60,
  },
  {
    size: 162,
    size3: 160,
    women: 208,
    shorts: 62,
    shorts3: 60,
    pirates: 62,
  },
  {
    size: 164,
    size3: 162,
    women: 212,
    shorts: 64,
    shorts3: 62,
    pirates: 64,
  },
  {
    size: 166,
    size3: 164,
    women: 216,
    shorts: 66,
    shorts3: 64,
    pirates: 66,
  },
  {
    size: 168,
    size3: 166,
    women: 220,
    shorts: 68,
    shorts3: 66,
    pirates: 68,
  },
  {
    size: 170,
    size3: 168,
    women: 224,
    shorts: 70,
    shorts3: 68,
    pirates: 70,
  },
  {
    size: 872,
    size3: 170,
    women: 228,
    shorts: 772,
    shorts3: 70,
    pirates: 772,
  },
  {
    size: 874,
    size3: 872,
    women: 232,
    shorts: 774,
    shorts3: 772,
    pirates: 774,
  },
  {
    size: 876,
    size3: 874,
    women: 0,
    shorts: 776,
    shorts3: 774,
    pirates: 776,
  },
  {
    size: 240,
    size3: 238,
    women: 0,
    shorts: 40,
    shorts3: 38,
    pirates: 40,
  },
  {
    size: 242,
    size3: 240,
    women: 0,
    shorts: 42,
    shorts3: 40,
    pirates: 42,
  },
  {
    size: 244,
    size3: 242,
    women: 0,
    shorts: 44,
    shorts3: 42,
    pirates: 44,
  },
  {
    size: 246,
    size3: 244,
    women: 0,
    shorts: 46,
    shorts3: 44,
    pirates: 46,
  },
  {
    size: 248,
    size3: 246,
    women: 0,
    shorts: 48,
    shorts3: 46,
    pirates: 48,
  },
  {
    size: 250,
    size3: 248,
    women: 0,
    shorts: 50,
    shorts3: 48,
    pirates: 50,
  },
  {
    size: 252,
    size3: 250,
    women: 0,
    shorts: 52,
    shorts3: 50,
    pirates: 52,
  },
  {
    size: 254,
    size3: 252,
    women: 0,
    shorts: 54,
    shorts3: 52,
    pirates: 54,
  },
  {
    size: 256,
    size3: 254,
    women: 0,
    shorts: 56,
    shorts3: 54,
    pirates: 56,
  },
  {
    size: 258,
    size3: 256,
    women: 0,
    shorts: 58,
    shorts3: 56,
    pirates: 58,
  },
  {
    size: 260,
    size3: 258,
    women: 0,
    shorts: 60,
    shorts3: 58,
    pirates: 60,
  },
  {
    size: 262,
    size3: 260,
    women: 0,
    shorts: 62,
    shorts3: 60,
    pirates: 62,
  },
  {
    size: 264,
    size3: 262,
    women: 0,
    shorts: 64,
    shorts3: 62,
    pirates: 64,
  },
  {
    size: 266,
    size3: 264,
    women: 0,
    shorts: 66,
    shorts3: 64,
    pirates: 66,
  },
  {
    size: 268,
    size3: 266,
    women: 0,
    shorts: 68,
    shorts3: 66,
    pirates: 68,
  },
  {
    size: 270,
    size3: 268,
    women: 0,
    shorts: 70,
    shorts3: 68,
    pirates: 70,
  },
  {
    size: 272,
    size3: 270,
    women: 0,
    shorts: 772,
    shorts3: 70,
    pirates: 772,
  },
  {
    size: 274,
    size3: 272,
    women: 0,
    shorts: 774,
    shorts3: 772,
    pirates: 774,
  },
  {
    size: 276,
    size3: 274,
    women: 0,
    shorts: 776,
    shorts3: 774,
    pirates: 776,
  },
];
