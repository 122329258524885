import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { fontSizes, fontWeights, spacing } from '@hultafors/snickers/helpers';

import { GridChild } from '../GridChild/GridChild';
import { LinkButton } from '../LinkButton/LinkButton';

export const ButtonGridChild = styled(GridChild)`
  justify-self: start;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    justify-self: center;
  }
`;

export const Content = styled.div`
  font-size: ${fontSizes.body};

  > p {
    font-size: ${fontSizes.body};
  }
`;

export const FirstContent = styled.div`
  font-size: ${fontSizes.body};

  > p {
    font-size: ${fontSizes.body};
  }

  margin-block-end: 20px;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block-end: 0;
  }
`;

export const StyledHeader = styled.h2`
  margin-inline: auto;
  text-align: left;
  margin-block: 0 24px;
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.header2};
  line-height: ${fontSizes.header2};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    max-width: 50%;
    text-align: center;
  }
`;

export const StyledLinkButton = styled(LinkButton)`
  margin-block-start: ${spacing.regular};
`;
