import styled from 'styled-components';

import { colors } from '@hultafors/snickers/helpers';

interface FavoriteStyledProps {
  $card?: boolean;
}

export const FavoriteStyled = styled.button<FavoriteStyledProps>`
  appearance: none;
  border: none;
  border-radius: 0;
  background: transparent;
  display: inline-block;
  padding: 0;
  width: 48px;
  height: 48px;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }

  svg.star path {
    stroke: ${colors.workwearBlack};
    fill: transparent;
    transition: all 0.2s;
  }

  svg.remove circle {
    fill: ${colors.workwearBlack};
    transition: all 0.2s;
  }

  &:hover,
  &.Active {
    svg.star path {
      stroke: ${colors.workwearYellow};
      fill: ${colors.workwearYellow};
    }
  }

  &:hover {
    svg.remove circle {
      fill: ${colors.gray1};
      transition: all 0.2s;
    }
  }
`;
