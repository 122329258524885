import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/snickers/helpers';

export const FactBoxStyled = styled.div`
  background-color: ${colors.gray4};
  padding: ${spacing.medium} 20px;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: ${spacing.huge} 0;
  }

  .FactInner {
    background-color: ${colors.white};
    padding: 20px;

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding: ${spacing.large} 65px;
    }

    p {
      line-height: ${lineHeights.l};
      max-width: 695px;
      margin: 16px auto;
      white-space: pre-wrap;

      &:last-of-type {
        margin-block-end: 0;
      }

      &.FactboxLink {
        margin-block-start: 36px;
      }

      a {
        font-weight: ${fontWeights.medium};
        font-size: ${fontSizes.l};
        text-decoration: underline;
      }
    }
  }
`;
export const Heading = styled.h2`
  font-weight: 500;
  font-size: ${fontSizes.h3};
  line-height: ${lineHeights.m};
  color: ${colors.workwearBlack};
  display: flex;
  align-items: center;
  max-width: 695px;
  margin: 0 auto;

  img,
  svg {
    height: 40px;
    margin-inline-end: 16px;
  }
`;
