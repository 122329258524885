import Link from 'next/link';
import styled, { css } from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/snickers/helpers';

export const OfficeCardStyled = styled.div`
  > * + * {
    margin-block-start: 30px;
  }
`;

const textStyle = css`
  font-family: ${fontFamilies.faktPro};
  font-size: ${fontSizes.m};
  font-weight: ${fontWeights.normal};
  line-height: 28px;
  letter-spacing: 0.2px;
  margin-block-start: 0;
`;

export const Address = styled.p`
  ${textStyle};
`;

export const ViewOnMap = styled.p`
  ${textStyle};
`;

export const LeftIconLink = styled(Link)`
  ${textStyle};
  position: relative;
  display: flex;
  align-items: center;

  svg {
    margin-inline-end: ${spacing.tinier};
  }
`;

export const EmailAndPhoneWrapper = styled.div`
  display: flex;
  border-top: thin solid ${colors.gray4};

  > * {
    padding: ${spacing.small} 0;
  }

  > * + * {
    margin-left: ${spacing.small};
    padding-left: ${spacing.small};
    border-left: thin solid ${colors.gray4};
  }
`;

export const Website = styled(Link)`
  ${textStyle};
  margin-block-start: 0;
  border-top: thin solid ${colors.gray4};
  display: flex;
  padding: ${spacing.small} 0;

  svg {
    display: block;
    margin-left: ${spacing.tinier};
  }
`;
