import styled from 'styled-components';

import {
  fontFamilies,
  fontWeights,
  lineHeights,
} from '@hultafors/snickers/helpers';

export const GuidePlugStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  background-image: url('/svg/yellow-pattern.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 20px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;

  .ContentWrapper {
    img {
      width: 48px;
      height: 48px;
    }
  }
`;

export const Title = styled.p`
  font-family: ${fontFamilies.mikro};
  font-weight: ${fontWeights.bold};
  font-size: 20px;
  line-height: ${lineHeights.s};
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-block: 8px 0;
`;

export const Intro = styled.p`
  font-family: ${fontFamilies.faktPro};
  font-size: 14px;
  line-height: 24px;
  max-width: 400px;
  margin-block-start: 0;
`;
