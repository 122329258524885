import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/snickers/helpers';

interface QuoteBlockStyledProps {
  $fullWidthRight?: boolean;
}

export const QuoteBlockStyled = styled.div<QuoteBlockStyledProps>`
  h4 {
    font-weight: ${fontWeights.medium};
    font-size: ${fontSizes.l};
    line-height: ${lineHeights.m};
    margin: 10px 0;

    @media screen and (min-width: ${breakpoints.desktop}) {
      margin: 20px 0 6px;
    }
  }

  p,
  li {
    line-height: 24px;
    margin: 0 0 12px;
    white-space: pre-wrap;

    strong {
      font-weight: ${fontWeights.medium};
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      line-height: 26px;
    }
  }

  ol,
  ul {
    margin: 24px 0;
  }
`;

export const Quote = styled.span`
  position: relative;
  font-size: 20px;
  line-height: 32px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    font-size: ${fontSizes.supermegabody};
    line-height: 38px;
  }

  &::before {
    content: '';
    background-image: url('/svg/yellow-line.svg');
    display: block;
    width: 48px;
    height: 48px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    left: -60px;
  }
`;

export const QuoteInner = styled.div`
  background-color: ${colors.gray4};
  padding: 35px 27px 35px 70px;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 35px 80px 35px 120px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: 90px 0 90px 200px;
  }
`;

export const QuoteWrapper = styled.div`
  position: relative;
  margin: 20px 0 30px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: 30px -4em 70px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    display: block;
    background-color: ${colors.gray4};
    z-index: -1;
  }
`;
