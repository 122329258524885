import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/snickers/helpers';

export const DocumentLinkBlockStyled = styled.div`
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  max-width: 1440px;
  margin-top: 0 !important;
  margin-inline: auto;
  padding-inline: 20px;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    padding-inline: 0;
  }

  a {
    display: flex;
    align-items: center;
    height: 64px;
    border-top: 1px solid ${colors.gray4};
    font-size: 14px;
    letter-spacing: 0.5px;
    text-decoration: none;
    grid-column: span 5;

    &:hover {
      color: ${colors.gray1};
    }

    &:last-of-type {
      border-bottom: 1px solid ${colors.gray4};
    }

    img,
    svg {
      margin-inline-end: 12px;
    }

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      grid-column: 2 / span 10;
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      grid-column: 3 / span 8;
    }

    @media screen and (min-width: ${breakpoints.desktopMedium}) {
      grid-column: 4 / span 6;
    }
  }
`;
