import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/snickers/helpers';

import { Box } from '../Box/Box';

interface DialogStyledProps {
  $height?: CSSProperties['height'];
  $width?: CSSProperties['width'];
  $top?: CSSProperties['top'];
  $right?: CSSProperties['right'];
  $bottom?: CSSProperties['bottom'];
  $left?: CSSProperties['left'];
  $isFullscreen?: boolean;
}

export const DialogWrapper = styled(Box)<DialogStyledProps>`
  position: fixed;
  ${({
    $top = 'inherit',
    $bottom = 'inherit',
    $left = 'inherit',
    $right = 'inherit',
  }) => css`
    inset: ${$top} ${$right} ${$bottom} ${$left};
  `};
  margin: auto;
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};
  z-index: 12;
  box-sizing: border-box;
  color: ${colors.solidBlack};
  background-color: ${colors.white};
  overflow-y: auto;
`;

export const DialogHeader = styled.div<DialogStyledProps>`
  width: auto;
  position: absolute;
  top: ${spacing.small};
  right: ${spacing.small};
  z-index: 10;

  ${({ $isFullscreen }) =>
    $isFullscreen
    && css`
      @media screen and (min-width: ${breakpoints.mobileMax}) {
        top: ${spacing.regular};
        right: ${spacing.medium};
      }
    `};

  button {
    padding: 0;
    width: 40px;
    height: 40px;
    min-height: 40px;

    img {
      height: 24px;
      width: 24px;
    }
  }
`;

export const DialogContent = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-y: auto;
  padding: 0 ${spacing.medium};
`;
