import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/snickers/helpers';

import { LargeCampaignPlugProps } from './LargeCampaignPlug';

export const CampaignPlugStyled = styled.article`
  margin-block-end: 15px;
  height: 424px;

  @media screen and (min-width: ${breakpoints.largeMobile}) {
    height: 460px;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    height: 460px;
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    height: 600px;
  }

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    height: 800px;
  }

  @media screen and (min-width: ${breakpoints.desktopLarge}) {
    height: 800px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-block-end: 30px;
  }
`;

export const SmallText = styled.p`
  font-family: ${fontFamilies.faktPro};
  font-size: ${fontSizes.body};
  line-height: ${lineHeights.bodyMobile};
  margin-block-start: 0;

  @media screen and (max-width: ${breakpoints.bigMobile}) {
    display: none;
  }
`;

export const Content = styled.div<Pick<LargeCampaignPlugProps, 'image'>>`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: ${({ image }) => (image ? colors.white : colors.workwearBlack)};
    font-size: 14px;
    font-weight: ${fontWeights.medium};
    text-shadow: 0 1px 8px rgb(0 0 0 / 25%);
    margin: 0;
    margin-block-end: 32px;
  }

  .plug-button {
    min-width: 188px;
    padding: 0 2rem;
    width: fit-content;
  }
`;

export const Heading = styled.p`
  font-family: ${fontFamilies.mikro};
  font-weight: ${fontWeights.bold};
  text-shadow: 0 1px 8px rgb(0 0 0 / 25%);
  font-size: 26px;
  line-height: ${lineHeights.s};
  color: ${colors.white};
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-block: 8px 20px;
  padding: 0 16px;
  text-align: center;

  @media screen and (max-width: ${breakpoints.tablet}) {
    font-size: 20px;
  }
`;

export const StyledLink = styled(Link)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const CampaignPlugImage = styled.div`
  position: absolute;
  inset: 0;

  &::after {
    content: '';
    height: 100%;
    background-image: url('/svg/color-puff-bg.svg');
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
  }
`;
