import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontWeights } from '@hultafors/snickers/helpers';

interface TabsStyledProps {
  $desktopMenu?: boolean;
}

export const TabsStyled = styled.div<TabsStyledProps>`
  display: flex;
  box-sizing: border-box;
  height: 58px;
  padding-left: ${({ $desktopMenu }) => ($desktopMenu ? '0' : '20px')};
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: ${({ $desktopMenu }) =>
    $desktopMenu ? 'center' : 'normal'};
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  overflow: auto hidden;
  border-bottom: 1px solid ${colors.gray4};
  scrollbar-width: none;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  input[type='radio'] {
    display: none;
  }

  label {
    height: 100%;
    margin-inline-end: ${({ $desktopMenu }) => ($desktopMenu ? '0' : '30px')};
    display: flex;
    flex-direction: column;
    align-items: ${({ $desktopMenu }) => ($desktopMenu ? 'center' : 'normal')};
    flex: ${({ $desktopMenu }) => ($desktopMenu ? '0 0 170px' : '0 1 auto')};
    scroll-snap-align: center;

    ${({ $desktopMenu }) =>
      $desktopMenu
      && css`
        &:hover {
          .categoryLabel {
            color: ${colors.workwearBlack};
          }
        }
      `}

    .categoryLabel {
      position: relative;
      display: inline-flex;
      height: 28px;
      font-weight: ${fontWeights.medium};
      color: ${colors.gray2};
      white-space: nowrap;
      margin-block-start: 16px;
      cursor: pointer;

      @media screen and (min-width: ${breakpoints.desktop}) {
        transition: all 0.2s linear;
      }
    }

    &:last-of-type {
      padding-inline-end: ${({ $desktopMenu }) =>
        $desktopMenu ? '0' : '20px'};
      margin-inline-end: 0;
    }
  }

  input.active + label {
    .categoryLabel {
      color: ${colors.workwearBlack};
    }

    .categoryLabel::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 16px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: ${colors.workwearBlack};
    }
  }
`;
