import styled from 'styled-components';

import { fontSizes } from '@hultafors/snickers/helpers';

export const PagerStyled = styled.div`
  .PagerInner {
    max-width: 335px;
    margin: 0 auto;
  }

  .Pager {
    margin-block-end: 5px;
    text-align: center;
    font-size: ${fontSizes.m};
  }

  .Gauge {
    margin: 0 auto 25px;
    max-width: 170px;
  }
`;
