import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  lineHeights,
} from '@hultafors/snickers/helpers';

export const ListStyled = styled.div`
  font-family: ${fontFamilies.fontRegular};
  line-height: ${lineHeights.bodyMobile};
  color: ${colors.workwearBlack};
  font-size: ${fontSizes.body};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    line-height: ${lineHeights.bodyDesktop};
    font-size: ${fontSizes.body};
  }

  ul {
    padding-left: 1rem;

    li {
      margin: 1rem 0;
    }
  }
`;
